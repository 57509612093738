import { alterSyncStatus, pullFarms } from '../../syncWorker'
import { fromDTO, toDTO } from '../resources/autoMapping'

import { AVAILABLE_DTO_MAPPINGS } from '../resources/constants'
import { Q } from '@nozbe/watermelondb'
import _ from 'lodash'
import database from '../index'
import hasInternet from '../../utils/recognizeInternetConnection'
import moment from 'moment'
import uuid from 'react-uuid'

export default class Repository {
  /**
   * constructor,
   * @param  {String} entityName name of model class of the table to manipulate
   */
  constructor(entityName) {
    // This assumes entityName is one of elements in array ../resources/constants.AVAILABLE_ENTITIES
    const collectionName =
      entityName === 'FARM_USERS'
        ? 'farms__rel__users'
        : entityName?.toLowerCase()
    this.dtoMapping = AVAILABLE_DTO_MAPPINGS[entityName]
    this.collection = database.collections.get(collectionName)
  }
  _success = response => {
    return {
      success: true,
      response
    }
  }
  _error = exception => {
    return {
      success: false,
      exception
    }
  }

  _fetchChildren = async entry => {
    //TODO: Refactor this part to be faster and avoid double fetching
    let ret = {}
    if (this.dtoMapping.children) {
      for (const child of this.dtoMapping.children) {
        const repository = new Repository(child.type)
        const children = await entry[child.name].fetch()
        ret[child.name] = _.compact(
          await Promise.all(
            children.map(
              async element =>
                !element.isDeleted &&
                (await repository.getById(element.id)).response
            )
          )
        )
      }
    }

    return ret
  }

  /**
   * post an object of model used in constructor in the database,
   * @param  {Object} obj object to be posted in the database
   * @return {Object}     response, json object with a success flag and, in case of success equal to true, the entity posted or, in case success equal to false, an exception object and an message
   */
  post = async obj => {
    try {
      let now = moment.utc()
      obj.isDeleted = false
      let response = await database.action(async () => {
        let newObj = await this.collection.create(entity => {
          entity._raw.id = obj.id || uuid()
          entity._raw.updated_at = now.valueOf()
          entity._raw.created_at = now.valueOf()
          fromDTO(this.dtoMapping, obj, entity)
        })
        return newObj
      })

      hasInternet() && alterSyncStatus(1)

      return this._success({
        ...toDTO(this.dtoMapping, response),
        ...(await this._fetchChildren(response))
      })
    } catch (exception) {
      return this._error(exception)
    }
  }

  postDeleted = async obj => {
    try {
      let now = moment.utc()
      obj.isDeleted = true
      let response = await database.action(async () => {
        let newObj = await this.collection.create(entity => {
          entity._raw.id = obj.id || uuid()
          entity._raw.updated_at = now.valueOf()
          entity._raw.created_at = now.valueOf()
          fromDTO(this.dtoMapping, obj, entity)
        })

        return newObj
      })
      return this._success({
        ...toDTO(this.dtoMapping, response),
        ...(await this._fetchChildren(response))
      })
    } catch (exception) {
      return this._error(exception)
    }
  }

  /**
   * update an object of model used in constructor in the database,
   * @param  {Object} obj object to be updated in the database
   * @return {Object}     response, json object with a success flag and, in case of success equal to true, the entity updated or, in case success equal to false, an exception object and an message
   */
  update = async obj => {
    try {
      const objId = obj.id
      let objSaved = await this.collection.find(obj.id)
      if (!objSaved) {
        return this._error(
          new Error('Object does not exists'),
          'object not found'
        )
      }
      delete obj.id
      await database.action(async () => {
        await objSaved.update(entity => {
          entity._raw.updated_at = moment.utc().valueOf()
          fromDTO(this.dtoMapping, obj, entity)
        })
      })

      hasInternet() && alterSyncStatus(1)

      let updatedObj = await this.collection.find(objId)
      return this._success({
        ...toDTO(this.dtoMapping, updatedObj),
        ...(await this._fetchChildren(updatedObj))
      })
    } catch (exception) {
      return this._error(exception)
    }
  }

  /**
   * delete an object of model used in constructor in the database,
   * @param  {String} id  id of object to be deleted in the database
   * @return {Object}     response, json object with a success flag and, in case of success equal to true or, in case success equal to false, an exception object and an message
   */
  delete = async (id, priority = false) => {
    try {
      let objSaved = await this.collection.find(id)
      if (!objSaved)
        return this._error(
          new Error('Object does not exists'),
          'object not found'
        )
      await database.action(async () => {
        //TODO: Move this function to models
        //(markAsDeleted seems to work in a different way, setting an internal status as deleted)
        await objSaved.update(entity => {
          entity.isDeleted = true
          entity._raw.updated_at = moment.utc().valueOf()
          entity.priority = priority
        }) // syncable
      })

      hasInternet() && alterSyncStatus(1)

      return this._success(undefined)
    } catch (exception) {
      return this._error(exception)
    }
  }

  /**
   * clean an object of model used in constructor in the database,
   * @param  {Object} farm  id of object to be deleted in the database
   * @return {Object}     response, json object with a success flag and, in case of success equal to true or, in case success equal to false, an exception object and an message
   */
  cleanDataToWatermelon = async (farm) => {
    try {
      let objSaved = await this.collection.find(farm.id)
      let collectionWithCorral = []
      let collectionWithoutCorral = []

      if (!objSaved) {
        return this._error(
          new Error('Object does not exists'),
          'object not found'
        )
      }

      const corrals = await database.collections
        .get('corrals')
        .query(Q.where('farm_id', farm.id))
        .fetch()

      if (farm.retiro) {
        collectionWithCorral = await database.collections
          .get('corrals')
          .query(Q.where('farm_id', farm.id))
          .fetch()
      } else {
        collectionWithoutCorral = await database.collections
          .get('batches')
          .query(Q.where('corral_id', corrals[0].id))
          .fetch()
      }

      await database.action(async () => {
        if (farm.retiro) {
          collectionWithCorral.map(async (item) => {
            await item.markAsDeleted()
          })
          corrals.map(async (item) => {
            await item.destroyPermanently()
          })
        } else {
          collectionWithoutCorral.map(async (item) => {
            await item.destroyPermanently()
          })
          await corrals[0].destroyPermanently()
        }
      })

      await pullFarms([farm.id], {}, false, false, true)

      return this._success(undefined)
    } catch (exception) {
      return this._error(exception)
    }
  }

  /**
   * get all objects of model used in constructor in the database,
   * @return {Object}     response, json object with a success flag and, in case of success equal to true, the list of entity from database or, in case success equal to false, an exception object and an message
   */
  get = async () => {
    try {
      let entities = await this.collection
        .query(Q.where('isDeleted', false))
        .fetch()
      //console.log(entities)
      let response = await Promise.all(
        entities.map(async entity => ({
          ...toDTO(this.dtoMapping, entity),
          ...(await this._fetchChildren(entity))
        }))
      )
      return this._success(response)
    } catch (exception) {
      return this._error(exception)
    }
  }

  getDeleted = async () => {
    try {
      let entities = await this.collection
        .query(Q.where('isDeleted', true))
        .fetch()
      //console.log(entities)
      let response = await Promise.all(
        entities.map(async entity => ({
          ...toDTO(this.dtoMapping, entity),
          ...(await this._fetchChildren(entity))
        }))
      )
      return this._success(response)
    } catch (exception) {
      return this._error(exception)
    }
  }

  /**
   * get a list of objects of model used in constructor in the database filtered by parameter,
   * @param  {String} param parameter witch will filter the objects from database (value)
   * @param  {String} column attribute by witch the objects will be filtered (column)
   * @return {Object}     response, json object with a success flag and, in case of success equal to true, the list of entity from database or, in case success equal to false, an exception object and an message
   */
  getByParam = async (column, param) => {
    try {
      let entities = await this.collection
        .query(Q.where(column, param), Q.where('isDeleted', false))
        .fetch()
      //console.log(entities)
      let response = await Promise.all(
        entities.map(async entity => ({
          ...toDTO(this.dtoMapping, entity),
          ...(await this._fetchChildren(entity))
        }))
      )
      return this._success(response)
    } catch (exception) {
      return this._error(exception)
    }
  }

  getByParamDeleted = async (column, param) => {
    try {
      let entities = await this.collection
        .query(Q.where(column, param))
        .fetch()
      //console.log(entities)
      let response = await Promise.all(
        entities.map(async entity => ({
          ...toDTO(this.dtoMapping, entity),
          ...(await this._fetchChildren(entity))
        }))
      )
      return this._success(response)
    } catch (exception) {
      return this._error(exception)
    }
  }


  /**
   * get an object of model used in constructor in the database by id,
   * @param  {String} id parameter of object to be found
   * @return {Object}     response, json object with a success flag and, in case of success equal to true, the object from database or, in case success equal to false, an exception object and an message
   */
  getById = async id => {
    try {
      let response = await this.collection.find(id)
      //console.log(response)
      return this._success({
        ...toDTO(this.dtoMapping, response),
        ...(await this._fetchChildren(response))
      })
    } catch (exception) {
      return this._error(exception)
    }
  }

  /**
   *get a list of objects of model used in constructor in the database filtered by parameter, querying a field that relates with other table
   * @param  {String} realtion name of relation invoked for querying (check the correspondent model)
   * @param  {String} column attribute by witch the objects will be filtered (column)
   * @param  {String} param parameter witch will filter the objects from database (value)
   * @return {Object}     response, json object with a success flag and, in case of success equal to true, the list of entity from database or, in case success equal to false, an exception object and an message
   */
  getByRelation = async (relation, column, param) => {
    //console.log(this.dtoMapping)
    try {
      let entities = await this.collection
        .query(
          Q.on(relation, column, param),
          Q.where('isDeleted', false),
          Q.on(relation, 'isDeleted', false)
        )
        .fetch()
      //console.log(entities)
      let response = await Promise.all(
        entities.map(async entity => ({
          ...toDTO(this.dtoMapping, entity),
          ...(await this._fetchChildren(entity))
        }))
      )
      //console.log(response)
      return this._success(response)
    } catch (exception) {
      return this._error(exception)
    }
  }

  getDeletedByRelation = async (relation, column, param) => {
    //console.log(this.dtoMapping)
    try {
      let entities = await this.collection
        .query(
          Q.on(relation, column, param),
        )
        .fetch()
      //console.log(entities)
      let response = await Promise.all(
        entities.map(async entity => ({
          ...toDTO(this.dtoMapping, entity),
          ...(await this._fetchChildren(entity))
        }))
      )
      //console.log(response)
      return this._success(response)
    } catch (exception) {
      return this._error(exception)
    }
  }
}
