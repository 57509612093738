import { AVAILABLE_ENTITIES, Repository } from '../../../database'
import { D0 } from '../../../models/d0.model'
import { idConditionDG } from '../../../utils/idsHelper'

export const ommitCowsToDGFinal = async (
  previousCows: D0[],
  currentCows: D0[]
) => {
  const repository = new Repository(AVAILABLE_ENTITIES.D0S)

  try {
    // Atualiza as vacas com condicaoDG empty ou emptyDiscard
    const emptyCowsPromises = previousCows
      .filter(
        (cow) =>
          cow.condicaoDG === idConditionDG.empty ||
          cow.condicaoDG === idConditionDG.emptyDiscard ||
          !cow.tipoPrenhe
      )
      .map(async (cow) => {
        await repository.update({
          ...cow,
          omit_from_dg_final: true,
        })
      })

    // Atualiza as vacas do IATF com dzeroPai igual ao id das vacas do data.cows
    const iatfCowsPromises = previousCows
      .map((cow) =>
        currentCows.filter(
          (cowFromCurrentIATF) =>
            cowFromCurrentIATF.dzeroPai === cow.id &&
            (cow.condicaoDG === idConditionDG.pregnant ||
              cow.condicaoDG === idConditionDG.pregnantBull ||
              cow.condicaoDG === idConditionDG.pregnantDiscard) &&
            !!cow.tipoPrenhe
        )
      )
      .flat()
      .map(async (cowFromCurrentIATF) => {
        await repository.update({
          ...cowFromCurrentIATF,
          omit_from_dg_final: true,
        })
      })

    // Espera até que todas as promessas sejam resolvidas
    await Promise.all([...emptyCowsPromises, ...iatfCowsPromises])
  } catch (error) {
    console.error(`There was a problem ommiting cows: ${error}`)
  }
}
