import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import _ from 'lodash'
import moment from 'moment'
import { Q } from '@nozbe/watermelondb'
import { Container, Grid } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'

import { getLoggedUser } from '../../redux/auth/actions'
import { downloadProofOfManagement } from '../../syncWorker'
import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import {
  FAZENDA,
  LOTE,
  REQUIRED_FIELD_MESSAGES,
  formatName,
} from '../../utils/formHelper'
import {
  idClOfEmpty,
  idConditionDG,
  idPregnantType,
} from '../../utils/idsHelper'
import {
  ANIMAL_DIED,
  ANIMAL_DISCARDED,
  ANIMAL_NOT_PRESENT,
  ANIMAL_UNDIAGNOSED,
  LOST_DEVICE,
} from '../../utils/constants'
import { sortList } from '../../utils/helper'
import { getBatchesByBatchIds } from '../../utils/batches'
import TopBar from '../../components/TopBar'
import TitleBar from '../../components/TitleBar'
import MainContainer from '../../components/MainContainer'
import Autocomplete, {
  autocompleteDomainValueOptionLabelSavingID,
} from '../../components/material/Autocomplete'
import ResponsiveTableWithoutInternalPagination from '../../components/ResponsiveTableWithoutInternalPagination'
import Button from '../../components/material/Button'
import { CELL_TYPES } from '../../components/PaginatedTable'
import TextField from '../../components/material/TextField'
import Prompt from '../../components/Prompt'
import Loading from '../../components/Loading'
import { ommitCowsToDGFinal, statisticsToDG } from './functions'
import { getPaths } from './functions/paths'

const DGRessinc = ({ ...props }) => {
  const [data, setData] = useState({
    diagnosticForAll: null,
    deviceUseForAll: null,
    searchValue: '',
    focusSearch: false,
    clearSearch: false,
    isEnterToSubmit: true,
    cows: props.cowsFromPreviousIATF,
    savedCows: [],
    filtedCows: [],
    hasAllCowsSaved: false,
  })
  const [modal, setModal] = useState({
    saveAllConfirm: false,
    finalizeConfirmation: false,
    fullPrenhezWarning: false,
    isIncompleteWarning: false,
  })
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const { trackEvent } = useTracking({
    page: props.management,
    date: new Date(),
  })

  const {
    prenhesTotal,
    vaziasTotal,
    descartesPrenhas,
    descartesVazias,
    prenhesTotalPercentage,
    vaziasTotalPercentage,
    clNao,
    clNoPercentage,
    clSim,
    clYesPercentage,
    clNaoAvaliado,
    clNotEvaluatedPercentage,
  } = statisticsToDG(data.savedCows)

  const handleFillAll = () => {
    const cowsToFill = _.differenceBy(
      data.searchValue !== '' ? data.filtedCows : data.cows,
      data.savedCows,
      (item) => item.id
    )

    const updatedCows = cowsToFill.map((cow) => ({
      ...cow,
      condicaoDG: !!data.diagnosticForAll && data.diagnosticForAll.id,
      condicaoDG_error: null,
      tipoPrenhe:
        !!data.diagnosticForAll &&
          (data.diagnosticForAll.id === idConditionDG.pregnant ||
            data.diagnosticForAll.id === idConditionDG.pregnantBull ||
            data.diagnosticForAll.id === idConditionDG.pregnantDiscard)
          ? idPregnantType.normal
          : null,
      tipoPrenhe_error: null,
      clVazia:
        !!data.diagnosticForAll &&
        (data.diagnosticForAll.id === idConditionDG.empty ||
          data.diagnosticForAll.id === idConditionDG.emptyDiscard) &&
        idClOfEmpty.notEvaluated,
      dispositivoIntravaginal_error: null,
      numero_Uso_Disp:
        !!data.diagnosticForAll &&
        !!data.deviceUseForAll &&
        props.management === 'DG/D0'
          ? data.deviceUseForAll.id
          : cow.numero_Uso_Disp,
      numero_Uso_Disp_error: null,
      dataDg: moment.utc(),
    }))

    const cowsToRemove = _.intersectionBy(data.cows, cowsToFill, 'id')

    setData((prev) => ({
      ...prev,
      cows: sortList(
        [..._.differenceBy(prev.cows, cowsToRemove, 'id'), ...updatedCows],
        'codVaca'
      ),
      filtedCows: sortList(
        [
          ..._.differenceBy(prev.filtedCows, cowsToRemove, 'id'),
          ...updatedCows,
        ],
        'codVaca'
      ),
    }))
  }

  const handleSearch = (event, resetFilted = false) => {
    data.searchValue !== '' && setPage(0)

    const searchValue = event?.target?.value ?? ''

    const filtedCows = _.isEmpty(searchValue || resetFilted)
      ? data.cows
      : data.cows.filter(
        (cow) =>
          cow.codVaca?.toLowerCase().indexOf(searchValue.toLowerCase()) >=
          0 ||
          cow.codLeitorBastao
            ?.toLowerCase()
            .indexOf(searchValue.toLowerCase()) >= 0
      )

    setData((prev) => ({
      ...prev,
      searchValue,
      filtedCows: sortList(filtedCows, 'codVaca'),
    }))
  }

  const handleOnEnterPress = (e, cowId) => {
    const isASavedCow = data.savedCows.find((item) => item.id === cowId)

    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      setData((prev) => ({
        ...prev,
        isEnterToSubmit: false,
      }))
    }

    if (e.key === 'Enter') {
      setData((prev) => ({
        ...prev,
        isEnterToSubmit: true,
      }))
    }

    if (!!!isASavedCow) {
      if (e.key === 'Enter' && e.shiftKey === false && data.isEnterToSubmit) {
        e.preventDefault()
        handleSaveCow(cowId)
      }
    }
  }

  const handleSaveCow = async (cowId) => {
    trackEvent({ action: 'Salvou a DGRessinc de uma matriz', value: cowId })

    const cow = data.cows.find((cow) => cow.id === cowId)
    const { condicaoDG, observacaoDG, partidaName, lote } = cow
    const isDGD0 = props.management === 'DG/D0'
    const isPregnant =
      condicaoDG === idConditionDG.pregnant ||
      condicaoDG === idConditionDG.pregnantBull ||
      condicaoDG === idConditionDG.pregnantDiscard
    const isEmpty = condicaoDG === idConditionDG.empty

    const updateCowError = (prev, field) => ({
      ...prev,
      cows: prev.cows.map((cow) => {
        if (cow.id === cowId) {
          return { ...cow, [field]: REQUIRED_FIELD_MESSAGES.DEFAULT }
        }
        return cow
      }),
    })

    if (
      _.isEmpty(condicaoDG) &&
      (_.isEmpty(observacaoDG) ||
        !_.includes(
          [ANIMAL_NOT_PRESENT, ANIMAL_DIED, ANIMAL_UNDIAGNOSED],
          observacaoDG
        ))
    ) {
      setData((prev) => updateCowError(prev, 'condicaoDG_error'))
      return
    }

    if (isDGD0 && isEmpty && !cow.dispositivoIntravaginal) {
      setData((prev) => updateCowError(prev, 'dispositivoIntravaginal_error'))
      return
    }

    if (isDGD0 && isEmpty && !cow.numero_Uso_Disp) {
      setData((prev) => updateCowError(prev, 'numero_Uso_Disp_error'))
      return
    }

    if (isPregnant && !cow.tipoPrenhe) {
      setData((prev) => updateCowError(prev, 'tipoPrenhe_error'))
      return
    }

    const d0Repository = new Repository(AVAILABLE_ENTITIES.D0S)
    const cowToCurrentIATF = _.find(props.cowsFromCurrentIATF, {
      dzeroPai: cow.id,
    })

    setData((prev) => ({
      ...prev,
      focusSearch: prev.searchValue !== '' && true,
      clearSearch: prev.searchValue !== '' && true,
      cows: prev.cows.filter((cow) => cow.id !== cowId),
      filtedCows: prev.cows.filter((cow) => cow.id !== cowId),
    }))

    if (!cow.observacaoDG || cow.observacaoDG.length === 0) {
      cow.observacaoDG = '[N/A]'
    }

    try {
      const { response } = await d0Repository.update({ ..._.clone(cow) })

      response.partidaName = partidaName
      response.lote = lote

      if (props.management !== 'DG/D0') {
        const dnRepository = new Repository(AVAILABLE_ENTITIES.DNS)
        const dnPayload = {
          vacaId: cowToCurrentIATF.id,
          loteId: cowToCurrentIATF.loteId,
          dia: props.protocol.currentDay,
          observacao: cow.observacaoDG,
        }
        const existingRequest = await dnRepository.getByParam(
          'vaca_id',
          cowToCurrentIATF.id
        )
        const existingEntry = _.find(existingRequest.response, {
          dia: props.protocol.currentDay,
        })

        if (!_.isEmpty(existingEntry)) {
          await dnRepository.update({
            ...dnPayload,
            id: existingEntry.id,
            isDeleted:
              cow.condicaoDG === idConditionDG.pregnant ||
              cow.condicaoDG === idConditionDG.pregnantBull ||
              cow.condicaoDG === idConditionDG.pregnantDiscard,
          })
        } else {
          await dnRepository.post({
            ...dnPayload,
            isDeleted:
              cow.condicaoDG === idConditionDG.pregnant ||
              cow.condicaoDG === idConditionDG.pregnantBull ||
              cow.condicaoDG === idConditionDG.pregnantDiscard,
          })
        }
      }

      setData((prev) => ({
        ...prev,
        cows: sortList([...prev.cows, response], 'codVaca'),
        savedCows: [...prev.savedCows, response],
        searchValue: '',
        focusSearch: prev.searchValue !== '' && false,
        clearSearch: prev.searchValue !== '' && false,
      }))
    } catch (error) {
      console.error(
        `There was a problem updating cow ${cow.id}:${cow.codVaca}: ${error}`
      )
    } finally {
      handleSearch(null, true)
    }
  }

  const handleSaveAllCows = async () => {
    setLoading(true)
    setPage(0)

    const cowsToSave = _.differenceBy(
      data.searchValue !== '' ? data.filtedCows : data.cows,
      data.savedCows,
      (item) => item.id
    )

    for (const cow of cowsToSave) {
      handleSaveCow(cow.id)
    }

    setTimeout(() => {
      setLoading(false)
      setModal({
        ...modal,
        saveAllConfirm: false,
      })
    }, 1200)
  }

  const handleFinalize = async () => {
    trackEvent({ action: 'Finalizou o manejo de DGRessinc' })

    const batchRepository = new Repository(AVAILABLE_ENTITIES.BATCHES)
    const d0Repository = new Repository(AVAILABLE_ENTITIES.D0S)

    data.savedCows.forEach(async (cow) => {
      const cowToCurrentIATF = _.find(props.cowsFromCurrentIATF, {
        dzeroPai: cow.id,
      })
      let currentD0Payload = {
        ...cowToCurrentIATF,
        id: cowToCurrentIATF.id,
        observacaoDG:
          cow.observacaoDG === ANIMAL_DIED ? null : cow.observacaoDG,
        observacaoD0:
          cow.observacaoDG === ANIMAL_DIED
            ? cow.observacaoDG
            : cow.observacaoD0,
        ecc: cow.eccDG,
        peso_EM: cow.peso_d0resync,
        dispositivoIntravaginal: cow.dispositivoIntravaginal,
        numero_Uso_Disp: cow.numero_Uso_Disp,
        dataProcesso: moment.utc(),
      }

      await d0Repository.update(currentD0Payload)
    })

    ommitCowsToDGFinal(data.cows, props.cowsFromCurrentIATF)

    for (const batch of props.batches.data) {
      await batchRepository.update({
        id: batch.id,
        isFinalize: true,
        dias: props.protocol.currentDay,
        processoAtual: props.management,
      })

      if (props.path.hasCorralDefault) {
        props.history.push(`/farms/${props.path.farmId}`)
      } else {
        props.history.push(
          `/farms/${props.path.farmId}/corrals/${props.path.corralId}`
        )
      }
    }

    downloadProofOfManagement({
      values:
        props.management === 'DG/D0'
          ? ['lotes', 'd0s']
          : ['lotes', 'd0s', 'dns'],
      text:
        `Comprovante de manejo ${props.management} - ` +
        formatName(
          props.batches.data.map((item) => item.nomeLote),
          LOTE
        ) +
        ' - ' +
        formatName(props.path.farm, FAZENDA) +
        ' - ' +
        moment.utc().valueOf() +
        '.progerar',
      batchIds: props.match.params.id.split(';'),
    })
  }

  const validateDGs = () => {
    const incompleteCows = data.cows.filter((cow) => !data.savedCows.includes(cow));

    return incompleteCows.length === 0;
  };

  useEffect(() => {
    let savedCows = []

    props.cowsFromPreviousIATF.map((cow) => {
      if (!!cow.dataDg) {
        savedCows.push(cow)
      }

      return cow
    })

    setData({ ...data, savedCows })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <TopBar title={formatName('NOME TESTE', FAZENDA)} />
      <TitleBar
        title={
          <>
            Protocolo: {props.protocol.name}
            <br />
            Manejo: Diagnóstico de Gestação ({props.management})
          </>
        }
        buttons={[
          {
            onClick: () =>
              prenhesTotal === data.cows.length
                ? setModal({ fullPrenhezWarning: true })
                : !validateDGs()
                  ? setModal({ isIncompleteWarning: true })
                  : setModal({ finalizeConfirmation: true }),
            icon: <DoneIcon />,
            disabled:
              prenhesTotal === data.cows.length && props.isClientUser && true,
            label: 'Finalizar',
          },
        ]}
        paths={getPaths({
          path: props.path,
          isMerging: props.isMerging,
          label: `Diagnóstico de Gestação (${props.management})`,
        })}
      />
      {loading && <Loading />}
      <MainContainer
        hasSearch
        hasValue={data.searchValue !== '' ? true : false}
        focusSearch={data.focusSearch}
        clearSearch={data.clearSearch}
        handleSearch={(e) => handleSearch(e)}
        useVoiceRecognition
        preSearchChildren={
          <div
            className='grid grid-dg p-margin-bottom-1'
            style={{ padding: 0 }}
          >
            <div className='grid-item p-12 t-6 header-info-left'>
              <h2>Manejos e Produtos</h2>

              <ul>
                {props.protocol.managementProtocols.map((protocol, index) => (
                  <li key={`management_${index}`}>
                    <strong>
                      {`${protocol.management}${!!protocol.hormone || !!protocol.device ? ': ' : ' '
                        }`}
                    </strong>
                    {!!protocol.hormone &&
                      `${protocol.hormone} - ${protocol.dosage} mL`}
                    {!!protocol.device && protocol.device}
                  </li>
                ))}
              </ul>
            </div>

            <div className='grid-item p-12 t-6 header-info-right'>
              <h2 className='title-bigger'>
                TOTAL DE MATRIZES: {data.savedCows.length}/{data.cows.length}
              </h2>
              <h3>Data da IATF: {props.protocol.iatfDate}</h3>
            </div>
            <div className='grid-item p-12'>
              <hr />
              <ul>
                <li className='p-font-weight-700'>
                  {`Prenhes: ${prenhesTotal} (${prenhesTotalPercentage}%)`}
                </li>
                <li className='p-font-weight-700'>
                  {`Vazias: ${vaziasTotal} (${vaziasTotalPercentage}%)`}
                </li>
                <li className='p-font-weight-700'>
                  {`Descartes: ${descartesPrenhas} Prenhes e ${descartesVazias} Vazias`}
                </li>
                <li className='p-font-weight-700'>
                  {`Presença de CL: ${clNaoAvaliado} (${clNotEvaluatedPercentage}%) Não Avaliados, ${clNao} (${clNoPercentage}%) Não e ${clSim} (${clYesPercentage}%) Sim`}
                </li>
              </ul>
            </div>
            <div className='grid-item p-12'>
              <hr />
            </div>
          </div>
        }
      >
        <div className='grid grid-dg'>
          <div className='grid-item p-12 t-4 p-margin-bottom-3'>
            <Autocomplete
              id='diagnosticForAll'
              options={props.conditionsToDG}
              value={data.diagnosticForAll || null}
              label='Diagnóstico'
              getOptionLabel={autocompleteDomainValueOptionLabelSavingID(
                props.conditionsToDG
              )}
              onChange={(_, value) => {
                setData({
                  ...data,
                  diagnosticForAll: value,
                })
              }}
              tabIndex={data.searchValue !== '' ? -1 : null}
            />
          </div>
          {props.management === 'DG/D0' && (
            <div className='grid-item p-12 t-2 p-margin-bottom-3'>
              <Autocomplete
                id='deviceUseForAll'
                options={props.deviceUses}
                onChange={(_, value) => {
                  setData({
                    ...data,
                    deviceUseForAll: value,
                  })
                }}
                value={data.deviceUseForAll || null}
                getOptionLabel={autocompleteDomainValueOptionLabelSavingID(
                  props.deviceUses
                )}
                label='Uso'
                tabIndex={data.searchValue !== '' ? -1 : null}
              />
            </div>
          )}
          <div className='grid-item p-12 t-7 d-4'>
            <Button
              label='Preencher Todas'
              style={{
                width: '48%',
                height: '46px',
                marginTop: '10px',
                padding: 0,
              }}
              onClick={
                (!!data.diagnosticForAll || !!data.deviceUseForAll) &&
                handleFillAll
              }
              tabIndex={data.searchValue !== '' ? -1 : null}
            />
            <Button
              label='Salvar todas'
              tabIndex={data.searchValue !== '' ? -1 : null}
              style={{
                width: '48%',
                marginLeft: '4%',
                height: '46px',
                marginTop: '10px',
              }}
              disabled={
                data.savedCows.length === data.cows.length || props.isClientUser
              }
              onClick={() => setModal({ saveAllConfirm: true })}
            />
          </div>
          <div className='p-12'>
            <ResponsiveTableWithoutInternalPagination
              columns={[
                {
                  name: 'Matriz (ID)',
                  type: CELL_TYPES.FIELD,
                  field: (row) => (
                    <div>
                      {props.isMerging && (
                        <Grid item xs={12}>
                          {row.lote}
                        </Grid>
                      )}
                      <Grid item xs={12} style={{ fontSize: 20 }}>
                        {row.codVaca}
                      </Grid>

                      {row.partidaName && (
                        <Grid style={{ margin: '8px 0' }} item xs={12}>
                          <b>Sêmen: </b>
                          {row.partidaName}
                        </Grid>
                      )}

                      {row.previousObs &&
                        row.previousObs.map((obs, index) => (
                          <Grid
                            style={{ marginTop: 2 }}
                            item
                            key={`${row.id}_obs_${index}`}
                            xs={12}
                          >
                            <b>{obs.manejo}: </b>
                            {obs.obs}
                          </Grid>
                        ))}
                    </div>
                  ),
                  grid: 3,
                },
                {
                  name: 'Manejo',
                  type: CELL_TYPES.FIELD,
                  field: (row, index) => {
                    return (
                      <div className='grid'>
                        <div
                          className={`grid-item p-12 t-${!row.condicaoDG ||
                              row.condicaoDG === idConditionDG.pregnantDiscard
                              ? 5
                              : 4
                            }`}
                        >
                          <Autocomplete
                            id={`condicaoDG_${index}`}
                            options={props.conditionsToDG}
                            onChange={(e, value) => {
                              setData((prev) => ({
                                ...prev,
                                cows: prev.cows.map((cow) => {
                                  if (cow.id === row.id) {
                                    return {
                                      ...cow,
                                      condicaoDG: value && value.id,
                                      condicaoDG_error:
                                        !_.isEmpty(value) &&
                                          !_.isEmpty(value.id)
                                          ? null
                                          : REQUIRED_FIELD_MESSAGES.DEFAULT,
                                      tipoPrenhe:
                                        value &&
                                          (value.valor === 'Prenhe' ||
                                            value.valor === 'Prenhe Touro' ||
                                            value.valor === 'Descarte Prenhe')
                                          ? props.typesOfPregnancy.find(
                                            (tipo) => tipo.valor === 'Normal'
                                          ).id
                                          : null,
                                      clVazia:
                                        value &&
                                          (value.valor === 'Vazia' ||
                                            value.valor === 'Descarte Vazia')
                                          ? props.clOfEmpty.find(
                                            (tipo) =>
                                              tipo.valor === 'Não Avaliado'
                                          ).id
                                          : null,
                                      dispositivoIntravaginal:
                                        value && cow.dispositivoIntravaginal,
                                      observacaoDG: props.observations
                                        .filter((obs) => obs !== LOST_DEVICE)
                                        .includes(row.observacaoDG)
                                        ? null
                                        : row.observacaoDG,
                                      dataDg: moment.utc(),
                                    }
                                  }

                                  return cow
                                }),
                                filtedCows: prev.filtedCows.map((cow) => {
                                  if (cow.id === row.id) {
                                    return {
                                      ...cow,
                                      condicaoDG: value && value.id,
                                      condicaoDG_error:
                                        !_.isEmpty(value) &&
                                          !_.isEmpty(value.id)
                                          ? null
                                          : REQUIRED_FIELD_MESSAGES.DEFAULT,
                                      tipoPrenhe:
                                        value &&
                                          (value.valor === 'Prenhe' ||
                                            value.valor === 'Prenhe Touro' ||
                                            value.valor === 'Descarte Prenhe')
                                          ? props.typesOfPregnancy.find(
                                            (tipo) => tipo.valor === 'Normal'
                                          ).id
                                          : null,
                                      clVazia:
                                        value &&
                                          (value.valor === 'Vazia' ||
                                            value.valor === 'Descarte Vazia')
                                          ? props.clOfEmpty.find(
                                            (tipo) =>
                                              tipo.valor === 'Não Avaliado'
                                          ).id
                                          : null,
                                      dispositivoIntravaginal:
                                        value && cow.dispositivoIntravaginal,
                                      observacaoDG: props.observations.includes(
                                        row.observacaoDG
                                      )
                                        ? null
                                        : row.observacaoDG,
                                      dataDg: moment.utc(),
                                    }
                                  }

                                  return cow
                                }),
                                savedCows: prev.savedCows.filter(
                                  (cow) => cow.id !== row.id
                                ),
                              }))
                            }}
                            onKeyDown={
                              row.condicaoDG &&
                              row.condicaoDG.length > 3 &&
                              !props.isClientUser &&
                              ((e) => handleOnEnterPress(e, row.id))
                            }
                            value={row.condicaoDG || null}
                            getOptionLabel={autocompleteDomainValueOptionLabelSavingID(
                              props.conditionsToDG
                            )}
                            useDefaultOptionSelected
                            label='Diagnóstico'
                            error={!_.isEmpty(row.condicaoDG_error)}
                            helperText={row.condicaoDG_error}
                          />
                        </div>

                        <div
                          className={`grid-item p-12 t-${!row.condicaoDG ||
                              row.condicaoDG === idConditionDG.pregnantDiscard
                              ? 4
                              : 2
                            }`}
                        >
                          <Autocomplete
                            id={`eccDG_${index}`}
                            options={props.eccs}
                            onChange={(_, value) => {
                              setData((prev) => ({
                                ...prev,
                                cows: prev.cows.map((cow) => {
                                  if (cow.id === row.id) {
                                    return {
                                      ...cow,
                                      eccDG: value && value.id,
                                    }
                                  }

                                  return cow
                                }),
                                filtedCows: prev.filtedCows.map((cow) => {
                                  if (cow.id === row.id) {
                                    return {
                                      ...cow,
                                      eccDG: value && value.id,
                                    }
                                  }

                                  return cow
                                }),
                                savedCows: prev.savedCows.filter(
                                  (cow) => cow.id !== row.id
                                ),
                              }))
                            }}
                            onKeyDown={
                              row.eccDG &&
                              row.eccDG.length > 3 &&
                              !props.isClientUser &&
                              ((e) => handleOnEnterPress(e, row.id))
                            }
                            value={row.eccDG || null}
                            getOptionLabel={autocompleteDomainValueOptionLabelSavingID(
                              props.eccs
                            )}
                            useDefaultOptionSelected
                            label='ECC'
                          />
                        </div>

                        {row.condicaoDG === idConditionDG.pregnant ||
                          row.condicaoDG === idConditionDG.pregnantBull ||
                          row.condicaoDG === idConditionDG.pregnantDiscard ? (
                          <div className='grid-item p-12 t-3'>
                            <Autocomplete
                              id={`tipoPrenhe_${index}`}
                              options={props.typesOfPregnancy}
                              onChange={(e, value) => {
                                setData((prev) => ({
                                  ...prev,
                                  cows: prev.cows.map((cow) => {
                                    if (cow.id === row.id) {
                                      return {
                                        ...cow,
                                        tipoPrenhe: value && value.id,
                                        tipoPrenhe_error:
                                          _.isEmpty(value) ||
                                          (_.isEmpty(value.id) &&
                                            REQUIRED_FIELD_MESSAGES.DEFAULT),
                                      }
                                    }

                                    return cow
                                  }),
                                  filtedCows: prev.filtedCows.map((cow) => {
                                    if (cow.id === row.id) {
                                      return {
                                        ...cow,
                                        tipoPrenhe: value && value.id,
                                        tipoPrenhe_error:
                                          _.isEmpty(value) ||
                                          (_.isEmpty(value.id) &&
                                            REQUIRED_FIELD_MESSAGES.DEFAULT),
                                      }
                                    }

                                    return cow
                                  }),
                                  savedCows: prev.savedCows.filter(
                                    (cow) => cow.id !== row.id
                                  ),
                                }))
                              }}
                              onKeyDown={
                                row.tipoPrenhe &&
                                row.tipoPrenhe.length > 3 &&
                                !props.isClientUser &&
                                ((e) => handleOnEnterPress(e, row.id))
                              }
                              value={row.tipoPrenhe || null}
                              getOptionLabel={autocompleteDomainValueOptionLabelSavingID(
                                props.typesOfPregnancy
                              )}
                              useDefaultOptionSelected
                              label='Tipo'
                              error={!_.isEmpty(row.tipoPrenhe_error)}
                              helperText={row.tipoPrenhe_error}
                            />
                          </div>
                        ) : row.condicaoDG === idConditionDG.empty ||
                          row.condicaoDG === idConditionDG.emptyDiscard ? (
                          <div className='grid-item p-12 t-3'>
                            <Autocomplete
                              id={`clVazia_${index}`}
                              options={props.clOfEmpty}
                              onChange={(e, value) => {
                                setData((prev) => ({
                                  ...prev,
                                  cows: prev.cows.map((cow) => {
                                    if (cow.id === row.id) {
                                      return {
                                        ...cow,
                                        clVazia: value && value.id,
                                      }
                                    }

                                    return cow
                                  }),
                                  filtedCows: prev.filtedCows.map((cow) => {
                                    if (cow.id === row.id) {
                                      return {
                                        ...cow,
                                        clVazia: value && value.id,
                                      }
                                    }

                                    return cow
                                  }),
                                  savedCows: prev.savedCows.filter(
                                    (cow) => cow.id !== row.id
                                  ),
                                }))
                              }}
                              onKeyDown={
                                row.clVazia &&
                                row.clVazia.length > 3 &&
                                !props.isClientUser &&
                                ((e) => handleOnEnterPress(e, row.id))
                              }
                              value={row.clVazia || null}
                              getOptionLabel={autocompleteDomainValueOptionLabelSavingID(
                                props.clOfEmpty
                              )}
                              useDefaultOptionSelected
                              label='Presença de CL'
                            />
                          </div>
                        ) : (
                          <div className='grid-item p-12 t-2' />
                        )}

                        {row.condicaoDG === idConditionDG.empty &&
                          props.management === 'DG/D0' && (
                            <>
                              <div className='grid-item p-12 t-4'>
                                <Autocomplete
                                  id={`dispositivoIntravaginal_${index}`}
                                  options={props.devices}
                                  onChange={(e, value) => {
                                    setData((prev) => ({
                                      ...prev,
                                      cows: prev.cows.map((cow) => {
                                        if (cow.id === row.id) {
                                          return {
                                            ...cow,
                                            dispositivoIntravaginal:
                                              value && value.id,
                                            dispositivoIntravaginal_error:
                                              _.isEmpty(value) ||
                                              (_.isEmpty(value.id) &&
                                                REQUIRED_FIELD_MESSAGES.DEFAULT),
                                          }
                                        }

                                        return cow
                                      }),
                                      filtedCows: prev.filtedCows.map((cow) => {
                                        if (cow.id === row.id) {
                                          return {
                                            ...cow,
                                            dispositivoIntravaginal:
                                              value && value.id,
                                            dispositivoIntravaginal_error:
                                              _.isEmpty(value) ||
                                              (_.isEmpty(value.id) &&
                                                REQUIRED_FIELD_MESSAGES.DEFAULT),
                                          }
                                        }

                                        return cow
                                      }),
                                      savedCows: prev.savedCows.filter(
                                        (cow) => cow.id !== row.id
                                      ),
                                    }))
                                  }}
                                  onKeyDown={
                                    row.dispositivoIntravaginal &&
                                    row.dispositivoIntravaginal.length > 3 &&
                                    !props.isClientUser &&
                                    ((e) => handleOnEnterPress(e, row.id))
                                  }
                                  value={row.dispositivoIntravaginal || null}
                                  getOptionLabel={autocompleteDomainValueOptionLabelSavingID(
                                    props.devices
                                  )}
                                  useDefaultOptionSelected
                                  label='Dispositivo Intravaginal'
                                  error={
                                    !_.isEmpty(
                                      row.dispositivoIntravaginal_error
                                    )
                                  }
                                  helperText={row.dispositivoIntravaginal_error}
                                />
                              </div>

                              <div className='grid-item p-12 t-2'>
                                <Autocomplete
                                  id={`numero_Uso_Disp_${index}`}
                                  options={props.deviceUses}
                                  onChange={(e, value) => {
                                    setData((prev) => ({
                                      ...prev,
                                      cows: prev.cows.map((cow) => {
                                        if (cow.id === row.id) {
                                          return {
                                            ...cow,
                                            numero_Uso_Disp: value && value.id,
                                            numero_Uso_Disp_error:
                                              _.isEmpty(value) ||
                                              (_.isEmpty(value.id) &&
                                                REQUIRED_FIELD_MESSAGES.DEFAULT),
                                          }
                                        }

                                        return cow
                                      }),
                                      filtedCows: prev.filtedCows.map((cow) => {
                                        if (cow.id === row.id) {
                                          return {
                                            ...cow,
                                            numero_Uso_Disp: value && value.id,
                                            numero_Uso_Disp_error:
                                              _.isEmpty(value) ||
                                              (_.isEmpty(value.id) &&
                                                REQUIRED_FIELD_MESSAGES.DEFAULT),
                                          }
                                        }

                                        return cow
                                      }),
                                      savedCows: prev.savedCows.filter(
                                        (cow) => cow.id !== row.id
                                      ),
                                    }))
                                  }}
                                  onKeyDown={
                                    row.numero_Uso_Disp &&
                                    row.numero_Uso_Disp.length > 3 &&
                                    !props.isClientUser &&
                                    ((e) => handleOnEnterPress(e, row.id))
                                  }
                                  value={row.numero_Uso_Disp || null}
                                  getOptionLabel={autocompleteDomainValueOptionLabelSavingID(
                                    props.deviceUses
                                  )}
                                  useDefaultOptionSelected
                                  label='Uso'
                                  error={!_.isEmpty(row.numero_Uso_Disp_error)}
                                  helperText={row.numero_Uso_Disp_error}
                                />
                              </div>

                              <div className='grid-item p-12 t-3'>
                                <TextField
                                  id={`peso_d0resync_${index}`}
                                  value={row.peso_d0resync || null}
                                  onChange={(event) => {
                                    const value = event.target.value

                                    setData((prev) => ({
                                      ...prev,
                                      cows: prev.cows.map((cow) => {
                                        if (cow.id === row.id) {
                                          return {
                                            ...cow,
                                            peso_d0resync: parseInt(value),
                                          }
                                        }

                                        return cow
                                      }),
                                      filtedCows: prev.filtedCows.map((cow) => {
                                        if (cow.id === row.id) {
                                          return {
                                            ...cow,
                                            peso_d0resync: parseInt(value),
                                          }
                                        }

                                        return cow
                                      }),
                                      savedCows: prev.savedCows.filter(
                                        (cow) => cow.id !== row.id
                                      ),
                                    }))
                                  }}
                                  onKeyDown={
                                    row.peso_d0resync &&
                                    row.peso_d0resync.length > 3 &&
                                    !props.isClientUser &&
                                    ((e) => handleOnEnterPress(e, row.id))
                                  }
                                  label='Peso Inicial'
                                  type='number'
                                />
                              </div>
                            </>
                          )}

                        <div className='grid-item p-12 t-9'>
                          <Autocomplete
                            id={`observacaoDG_${index}`}
                            options={props.observations}
                            onChange={(e, value) => {
                              const isPresetObservation = props.observations
                                .filter((obs) => obs !== LOST_DEVICE)
                                .includes(value)

                              setData((prev) => ({
                                ...prev,
                                cows: prev.cows.map((cow) => {
                                  if (cow.id === row.id) {
                                    return {
                                      ...cow,
                                      observacaoDG: value,
                                      condicaoDG: isPresetObservation
                                        ? null
                                        : row.condicaoDG,
                                      condicaoDG_error: isPresetObservation
                                        ? null
                                        : row.condicaoDG_error,
                                      eccDG: isPresetObservation
                                        ? null
                                        : row.eccDG,
                                      tipoPrenhe: isPresetObservation
                                        ? null
                                        : row.tipoPrenhe,
                                      clVazia: isPresetObservation
                                        ? null
                                        : row.clVazia,
                                      dataDg: moment.utc(),
                                    }
                                  }

                                  return cow
                                }),
                                filtedCows: prev.filtedCows.map((cow) => {
                                  if (cow.id === row.id) {
                                    return {
                                      ...cow,
                                      observacaoDG: value,
                                      condicaoDG: isPresetObservation
                                        ? null
                                        : row.condicaoDG,
                                      condicaoDG_error: isPresetObservation
                                        ? null
                                        : row.condicaoDG_error,
                                      eccDG: isPresetObservation
                                        ? null
                                        : row.eccDG,
                                      tipoPrenhe: isPresetObservation
                                        ? null
                                        : row.tipoPrenhe,
                                      clVazia: isPresetObservation
                                        ? null
                                        : row.clVazia,
                                      dataDg: moment.utc(),
                                    }
                                  }

                                  return cow
                                }),
                                savedCows: prev.savedCows.filter(
                                  (cow) => cow.id !== row.id
                                ),
                              }))
                            }}
                            onKeyDown={
                              row.observacaoDG &&
                              row.observacaoDG.length > 3 &&
                              !props.isClientUser &&
                              ((e) => handleOnEnterPress(e, row.id))
                            }
                            label='Observações'
                            value={row.observacaoDG || null}
                            openField
                          />
                        </div>

                        <div
                          className='grid-item p-12 t-3'
                          style={{ textAlign: 'right' }}
                        >
                          <Button
                            label='OK'
                            style={{ width: '100%', marginTop: '12px' }}
                            onClick={() => handleSaveCow(row.id)}
                            disabled={
                              data.savedCows.includes(row) || props.isClientUser
                            }
                          />
                        </div>
                      </div>
                    )
                  },
                  grid: 9,
                },
              ]}
              data={data.searchValue !== '' ? data.filtedCows : data.cows}
              page={page}
              onPageChange={(_, page) => setPage(page)}
              className='table-dg'
            />
          </div>
        </div>

        <Prompt
          visible={modal.finalizeConfirmation}
          title='Deseja Finalizar?'
          message='Uma vez finalizado os dados não salvos serão perdidos.'
          buttons={[
            {
              label: 'Não',
              onClick: () => setModal({ finalizeConfirmation: false }),
            },
            {
              label: 'Sim',
              onClick: handleFinalize,
            },
          ]}
        />

        <Prompt
          visible={modal.saveAllConfirm}
          title='Confirmação:'
          message={`Deseja realmente salvar todas?`}
          buttons={[
            {
              label: 'Não',
              onClick: () =>
                setModal({
                  saveAllConfirm: false,
                }),
            },
            {
              label: 'Sim',
              onClick: handleSaveAllCows,
            },
          ]}
        />

        <Prompt
          visible={modal.fullPrenhezWarning}
          title='Erro ao finalizar.'
          message={`Este lote se encontra no processo de ressinc na IATF ${props.batches.currentIatf}, portanto, não é possível finalizar o diagnóstico referente à IATF ${props.batches.previousIatf} com 100% de prenhez.`}
          buttons={[
            {
              label: 'Ok',
              onClick: () => setModal({ fullPrenhezWarning: false }),
            },
          ]}
        />

        <Prompt
          visible={modal.isIncompleteWarning}
          title='Erro'
          message='Existem matrizes que não foram salvas.'
          buttons={[
            {
              label: 'OK',
              onClick: () => setModal({ isIncompleteWarning: false }),
            },
          ]}
        />
      </MainContainer>
    </Container>
  )
}

const propsFromDatabase = async (props) => {
  const management = props.location.state.manejo
  const farm = props.location.state.farm
  const corral = props.location.state?.retiro
  const batchIds = props.match.params.id.split(';')
  const isMerging = batchIds.length > 1
  const batches = await getBatchesByBatchIds(batchIds)
  const loggedUser = await getLoggedUser()
  const isClientUser = loggedUser.roles[0] === 'Cliente (somente visualização)'
  const previousBatches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'id',
    Q.oneOf(_.compact(_.uniq(batches.map((batch) => batch.parentBatchId))))
  )
  let cowsFromPreviousIATF = []
  let deadCows = []

  cowsFromPreviousIATF = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    Q.oneOf(previousBatches.map((b) => b.id))
  )
  const cowsFromCurrentIATF = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    Q.oneOf(batches.map((b) => b.id))
  )
  const protocol = await utils.getElement(
    AVAILABLE_ENTITIES.PROTOCOLS,
    batches[0].protocoloId
  )
  const partidas = await utils.getWithParam(
    AVAILABLE_ENTITIES.PARTIDA,
    'farm_id',
    farm.id
  )
  const bulls = await utils.getWithParam(
    AVAILABLE_ENTITIES.BULLS_NEW,
    'id',
    Q.oneOf(partidas.map((f) => f.touroId))
  )

  cowsFromPreviousIATF = await Promise.all(
    cowsFromPreviousIATF.map(async (cow) => {
      cow.lote = _.find(previousBatches, { id: cow.loteId }).nomeLote

      const iatf = await utils.getWithParam(
        AVAILABLE_ENTITIES.IATFS,
        'vacaId',
        cow.id
      )

      const dns = await utils.getWithParam(
        AVAILABLE_ENTITIES.DNS,
        'vacaId',
        cow.id
      )

      if (
        iatf[0]?.observacao === ANIMAL_DISCARDED ||
        iatf[0]?.observacao === ANIMAL_DIED ||
        dns[0]?.observacao === ANIMAL_DIED ||
        dns[0]?.observacao === `[${ANIMAL_DIED}] ${ANIMAL_DIED}` ||
        cow.observacaoD0 === ANIMAL_DIED
      ) {
        deadCows.push(cow.id)
      }

      const partida = _.find(partidas, { id: iatf[0]?.partidaId })
      const bull = !!partida && _.find(bulls, { id: partida.touroId })

      if (!!!bull && !!!partida) {
        cow.partidaName = null
      } else {
        cow.partidaName = `${!!bull ? bull.nome : ''} - ${!!partida &&
          (partida?.codigo || moment(partida?.data).format('DD/MM/YYYY') || '')
          }`
      }

      return cow
    })
  )

  const managementProtocols = await Promise.all(
    protocol.managementProtocols.map(async (selectedProtocol) => {
      let device = null
      let managementProtocol = null

      if (management === 'DG/D0') {
        device = await utils.getElement(
          AVAILABLE_ENTITIES.DOMAIN_VALUES,
          selectedProtocol.implantId
        )
      }

      managementProtocol = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        selectedProtocol.managementId
      )
      const day = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        selectedProtocol.day
      )
      const hormone = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        selectedProtocol.hormoneTypeId
      )

      const dosage = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        selectedProtocol.hormoneDosageId
      )

      if (device !== null) {
        device = {
          valor: device.valor,
          id: device.id,
        }
        managementProtocol = {
          valor: 'Dispositivo',
        }
      }

      if (management === 'DG/D0') {
        return {
          device: device?.valor,
          management: managementProtocol?.valor,
          hormone: hormone?.valor,
          dosage: dosage?.valor,
          day,
        }
      }

      return {
        management: managementProtocol?.valor,
        hormone: hormone?.valor,
        dosage: dosage?.valor,
        day,
      }
    })
  )
  const dayOfIatf = managementProtocols.find(
    (protocol) => protocol.management === 'IATF'
  )?.day?.valor
  const iatfDate = moment(batches[0].dataIatf)
    .add(parseInt(dayOfIatf.replace(/\D/g, '')), 'days')
    .format('DD/MM/YYYY')
  const currentDay = managementProtocols.find(
    (protocol) => protocol.day.valor === management.replace('DG/', '')
  )?.day?.id

  const conditionsToDG = _.sortBy(
    await utils.getDomainValuesBy('Condição DG'),
    [
      (condicao) => condicao.valor !== 'Prenhe',
      (condicao) => condicao.valor !== 'Prenhe Touro',
      (condicao) => condicao.valor !== 'Vazia',
      (condicao) => condicao.valor !== 'Descarte Prenhe',
      (condicao) => condicao.valor !== 'Descarte Vazia',
    ]
  )
  const deviceUses = _.sortBy(
    await utils.getDomainValuesBy('Tipo de Implante Predominante'),
    ['valor']
  )
  const eccs = _.sortBy(await utils.getDomainValuesBy('ECC'), [
    (ecc) => parseFloat(ecc.valor),
  ])
  const typesOfPregnancy = await utils.getDomainValuesBy('Tipo Prenhe')
  const clOfEmpty = await utils.getDomainValuesBy('CL')
  const devices = await utils.getDomainValuesBy('Dispositivo')
  const observations =
    management === 'DG/D0'
      ? [ANIMAL_NOT_PRESENT, ANIMAL_DIED, ANIMAL_UNDIAGNOSED]
      : [LOST_DEVICE, ANIMAL_NOT_PRESENT, ANIMAL_DIED, ANIMAL_UNDIAGNOSED]

  return {
    management,
    conditionsToDG,
    deviceUses,
    eccs,
    typesOfPregnancy,
    clOfEmpty,
    devices,
    observations,
    isMerging,
    protocol: {
      name: protocol.name,
      managementProtocols: managementProtocols.filter(
        (protocol) =>
          protocol.management !== 'IATF' &&
          protocol.day !== 'D0' &&
          !!protocol.management
      ),
      iatfDate,
      currentDay,
    },
    batches: {
      data: batches,
      currentIatf: batches[0].iatfCount,
      previousIatf: batches[0].iatfCount - 1,
    },
    cowsFromPreviousIATF: sortList(
      cowsFromPreviousIATF.filter(
        (cow) => !cow.omit_from_dg_final && deadCows.indexOf(cow.id) === -1
      ),
      'codVaca'
    ),
    cowsFromCurrentIATF: sortList(
      cowsFromCurrentIATF.filter((cow) => !cow.omit_from_dg_final),
      'codVaca'
    ),
    path: {
      farmId: farm.id,
      farm: farm.label,
      batchId: isMerging
        ? null
        : !!batches[0].originalBatchId
          ? batches[0].originalBatchId
          : batches[0].id,
      batch: isMerging ? 'Vários Lotes' : batches[0].nomeLote,
      corralId: corral.id,
      corral: corral.label,
      hasCorralDefault: corral.label === 'Retiro DEFAULT',
    },
    isClientUser,
  }
}

export default connect()(
  utils.withPropsFromDatabase(propsFromDatabase, withRouter(DGRessinc))
)
