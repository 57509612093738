import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import React, { Component } from 'react'
import ResponsiveTable, { CELL_TYPES } from '../../components/ResponsiveTable'

import AddIcon from '@material-ui/icons/Add'
import Container from '@material-ui/core/Container'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import TrainingsButton from '../Trainings/TrainingsButton'
import _ from 'lodash'
import { getLoggedUser } from '../../redux/auth/actions.js'
import { sortList } from '../../utils/helper'
import track from 'react-tracking'
import { verify } from './../../utils/verifyDuplicatePages';
import { withRouter } from 'react-router-dom'

@track(() => ({ page: 'Protocolos List', date: new Date() }))
class ProtocolsList extends Component {
  constructor(props) {
    super(props)

    const sortedProtocols = this.props.protocols
      .map(protocol => ({
        name: protocol.name,
        days:
          protocol.labels ||
          _.sortBy(
            _.uniq(
              protocol.managementProtocols.map(
                dailyProtocol => `D${dailyProtocol.day}`
              )
            ),
            [
              valor =>
                valor.startsWith('D') ? parseInt(valor.substring(1)) : 100
            ]
          ).join(' / '),
        isBeingUsed: protocol.isBeingUsed,
        updatable: protocol.updatable,
        id: protocol.id
      }))
      .sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        }
        return 0
      })

    const ordenedProtocols = sortList(sortedProtocols, 'name')

    this.state = {
      protocols: ordenedProtocols,
      ordenedProtocols,
      deleteConfirmationOpened: false,
      confirmationBatchesProtocol: false,
      selectedProtocol: null
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.deleteProtocol = this.deleteProtocol.bind(this)
  }

  handleSearch(e) {
    const val = e.target.value
    this.setState({
      protocols: _.isEmpty(val)
        ? this.state.ordenedProtocols
        : this.state.ordenedProtocols.filter(
          x => x.name.toLowerCase().indexOf(val.toLowerCase()) >= 0
        )
    })
  }

  @track((props, state, protocol) => ({ action: 'Deletou Protocolo', value: protocol[0] }))
  async deleteProtocol(selectedProtocol) {
    if (selectedProtocol === null) return

    let newState = {};
    const batches = await utils.getWithParam(
      AVAILABLE_ENTITIES.BATCHES,
      'protocol_id',
      selectedProtocol
    )
    
    if (batches.length > 0) {
      newState = {
        deleteConfirmationOpened: false,
        selectedProtocol: null,
        confirmationBatchesProtocol: true
      }
    } else {
      const repository = new Repository(AVAILABLE_ENTITIES.PROTOCOLS)
      const request = await repository.delete(selectedProtocol)
  
      newState = {
        deleteConfirmationOpened: false,
        selectedProtocol: null
      }
  
      if (request.success) {
        console.log(
          `Delete of protocol ${selectedProtocol} successful`
        )
        newState.protocols = this.state.protocols.filter(
          x => x.id !== selectedProtocol
        )
      } else {
        console.log(
          `Delete of protocol ${selectedProtocol} failed: ${request.exception}`
        )
      }
  
    }
    
    this.setState(newState)
  }

  @track({ action: 'Entrou na página de novo Protocolo' })
  handleAddNewProtocol = () => this.props.history.push('/protocols/create/')

  @track((props, state, protocol) => ({ action: 'Entrou na página de editar protocolo', value: protocol[0].id }))
  handleEditProtocol = (row) => this.props.history.push(`/protocols/${row.id}/update`)

  getTableColumns() {
    return [
      {
        name: 'Protocolo',
        type: CELL_TYPES.LINK,
        sortBy: true,
        label: row => row.name,
        onClick: row => {
          return () => this.props.history.push(`/protocols/${row.id}`)
        }
      },
      {
        name: 'Processos',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        label: row => row.days
      },
      {
        type: CELL_TYPES.BUTTON_ARR,
        buttons: row =>
          _.compact([
            {
              type: CELL_TYPES.BUTTON,
              icon: <EditIcon />,
              label: 'Editar',
              onClick: () => this.handleEditProtocol(row),
              disabled: !row.updatable || row.isBeingUsed
            },
            {
              name: '',
              type: CELL_TYPES.BUTTON,
              icon: <DeleteIcon />,
              label: 'Excluir',
              onClick: () =>
                this.setState({
                  deleteConfirmationOpened: true,
                  selectedProtocol: row.id,
                  selectedProtocolCode: row.name
                }),
              disabled: !row.updatable || row.isBeingUsed || this.props.user.roles[0].name === 'Cliente (somente visualização)'
            }
          ])
      }
    ];
  }

  render() {
    return (
      <Container className="page-protocols">
        <TopBar title={''} />
        <TitleBar
          title='Protocolos'
          buttons={[
            {
              onClick: this.handleAddNewProtocol,
              icon: <AddIcon />,
              label: 'Protocolo'
            }
          ]}
          paths={[
            {
              label: 'Protocolos'
            }
          ]}
        />
        <TrainingsButton page='/protocols' />
        <MainContainer hasSearch handleSearch={this.handleSearch}>
          <Container disableGutters>
            <ResponsiveTable
              columns={this.getTableColumns()}
              data={this.state.protocols}
              noDataMessage='Ainda não existe nenhuma vaca neste lote!'
              toDataExport={this.props}
              onSort={(sortedData) => this.setState({ protocols: sortedData })}
            />

            <Prompt
              visible={verify()}
              title='Página duplicada!'
              message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
                Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
              buttons={[
                {
                  autoFocus: false
                },
              ]}
            />

            <Prompt
              visible={this.state.deleteConfirmationOpened}
              title='Confirmar Exclusão'
              message={`Deseja realmente excluir este protocolo? (${this.state.selectedProtocolCode})?`}
              buttons={[
                {
                  label: 'Não',
                  onClick: () =>
                    this.setState({ deleteConfirmationOpened: false })
                },
                {
                  label: 'Sim',
                  onClick: () => this.deleteProtocol(this.state.selectedProtocol)
                }
              ]}
            />

            <Prompt
              visible={this.state.confirmationBatchesProtocol}
              title='Exclusão de Protocolo Não Permitida'
              message={`A exclusão deste protocolo ${this.state.selectedProtocolCode} não pode ser realizada, 
              pois existem lotes vinculados a ele`}
              buttons={[
                {
                  label: 'Fechar',
                  onClick: () =>
                    this.setState({ confirmationBatchesProtocol: false })
                }
              ]}
            />
          </Container>
        </MainContainer>
      </Container>
    )
  }
}

async function getUser() {
  const loggedUser = await getLoggedUser()
  const repository = new Repository(AVAILABLE_ENTITIES.USERS)
  const usersData = await repository.getById(loggedUser.userId)
  if (usersData.success) {
    return usersData.response
  }
  return []
}

async function getProtocols(userId) {
  const protocolsRepository = new Repository(AVAILABLE_ENTITIES.PROTOCOLS)
  //TODO: Change login info source after offline login is implemented
  const protocols = await protocolsRepository.get()
  if (protocols.success) {
    return protocols.response.filter(
      x => x.veterinary_id === userId || !x.veterinary_id
    )
  }
  return []
}

async function isAdmin() {
  const loggedUser = await getLoggedUser()
  return _.find(loggedUser.roles, el => el === 'Administrador')
}

async function getDomainValue(id) {
  const repository = new Repository(AVAILABLE_ENTITIES.DOMAIN_VALUES)
  const request = await repository.getById(id)
  if (request.success) {
    return request.response
  }
  return null
}

const propsFromDatabase = async props => {
  const user = await getUser()
  let is_admin = await isAdmin()
  let protocols = await getProtocols(user.id)
  protocols = await Promise.all(
    protocols.map(async protocol => {
      const days = await Promise.all(
        protocol.managementProtocols.map(managementProtocol =>
          getDomainValue(managementProtocol.day)
        )
      )
      protocol.labels = _.sortBy(
        _.compact(_.uniq(days.map(day => day && day.valor))),
        [valor => (valor.startsWith('D') ? parseInt(valor.substring(1)) : 100)]
      ).join('/')

      const batches = await utils.getWithParam(
        AVAILABLE_ENTITIES.BATCHES,
        'protocol_id',
        protocol.id
      )
      protocol.isBeingUsed = batches.length > 0
      protocol.updatable =
        protocol.veterinaryId === user.id ||
        (is_admin && !protocol.veterinaryId)
      return protocol
    })
  )
  return {
    user,
    protocols
  }
}

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(ProtocolsList)
)
