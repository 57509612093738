import * as batches from '../../utils/batches'
import * as sync from '../../../src/syncWorker'

import {
  ANIMAL_DIED,
  ANIMAL_DISCARDED,
  ANIMAL_NOT_INSEMINATED,
  ANIMAL_NOT_PRESENT,
  ANIMAL_NOT_PRESENT_TO_MANAGEMENT,
  ANIMAL_UNDIAGNOSED,
  FOUND_DEVICE_INSIDE_COW,
  LOST_DEVICE,
} from '../../utils/constants'
import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import Autocomplete, {
  autocompleteDefaultOnChangeSavingID,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteDomainValueOptionLabelSavingIDUsingDescription,
  autocompleteOptionLabelUsingCustomFieldSavingID,
  autocompleteOptionLabelUsingCustomHandlerSavingID,
} from '../../components/material/Autocomplete'
import Button, { COLORS } from '../../components/material/Button'
import {
  FAZENDA,
  LOTE,
  REQUIRED_FIELD_MESSAGES,
  RETIRO,
  formatName,
  isDateNullOrValid,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from '../../utils/formHelper'
import React, { Component, Fragment } from 'react'
import ResponsiveTable, { CELL_TYPES } from './../../components/ResponsiveTable'
import TextField, {
  textfieldDefaultOnChange,
} from '../../components/material/TextField'
import {
  currentDayIsDG,
  currentDayIsDGFinal,
  currentDayIsDN,
  currentDayIsIatf,
} from '../../utils/days'

import { Alert } from '@material-ui/lab'
import { Container } from '@material-ui/core'
import CustomSwitch from '../../components/CustomSwitch'
import DateField from '../../components/material/DateField'
import DeleteIcon from '@material-ui/icons/Delete'
import Divider from '@material-ui/core/Divider'
import DoneIcon from '@material-ui/icons/Done'
import MainContainer from '../../components/MainContainer'
import MicIcon from '@material-ui/icons/Mic'
import PaginatedTable from './../../components/PaginatedTable'
import Prompt from '../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import SaveIcon from '@material-ui/icons/Save'
import SpeechRecognition from '../../components/SpeechRecognition'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { eccSpeechRecognition } from '../../utils/eccSpeechRecognition'
import { getLoggedUser } from '../../redux/auth/actions'
import { idConditionDG } from '../../utils/idsHelper'
import moment from 'moment'
import track from 'react-tracking'
import { withRouter } from 'react-router-dom'

const obsIATFOptions = [
  FOUND_DEVICE_INSIDE_COW,
  ANIMAL_NOT_PRESENT_TO_MANAGEMENT,
  ANIMAL_NOT_INSEMINATED,
  ANIMAL_DISCARDED,
]

const obsDGOptions = [
  LOST_DEVICE,
  ANIMAL_NOT_PRESENT,
  ANIMAL_DIED,
  ANIMAL_UNDIAGNOSED,
]

@track(() => ({ page: 'Incluir matriz', date: new Date() }))
class D0AfterResync extends Component {
  constructor(props) {
    super(props)
    let sortedCows = props.vacas.reverse()

    this.state = {
      ...props.initialValues,
      count: this.props.vacas.length,
      showingFinalizeConfirmation: false,
      batchId: [props.batch.id],
      loading: false,
      allCows: sortedCows,
      searchError: false,
      checked: true,
      confirmedBackupDownload: true,
      showingNoBackupConfirmation: false,
      selectedCows: sortedCows,
      warning: true,
      iatfCows: [],
      clD0: this.props.clD0.filter((x) => x.valor === 'Não Avaliado')[0].id,
      createdD0: null,
      savedCows: [],
      savedIatfs: [],
      savedDGs: [],
      focusDate: false,
      dataPartoIndividual: null,
      cowsWithBlockingObs: [],
      renderIndex: 0,
    }
    this.codVaca = React.createRef()

    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.renderBullLabel = this.renderBullLabel.bind(this)
    this.autocompleteDefaultOnChangeSavingID =
      autocompleteDefaultOnChangeSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)
    this.autocompleteOptionLabelUsingCustomFieldSavingID =
      autocompleteOptionLabelUsingCustomFieldSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingIDUsingDescription =
      autocompleteDomainValueOptionLabelSavingIDUsingDescription.bind(this)
    this.onClean = this.onClean.bind(this)
    this.fullClean = this.fullClean.bind(this)
    this.finalize = this.finalize.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.renderField = this.renderField.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.renderTitle = this.renderTitle.bind(this)
    this.autocompleteOptionLabelUsingCustomHandlerSavingID =
      autocompleteOptionLabelUsingCustomHandlerSavingID.bind(this)
    this.updateCondition = this.updateCondition.bind(this)
    this.isDateNullOrValid = isDateNullOrValid.bind(this)
    this.deleteCow = this.deleteCow.bind(this)
    this.possibleDay = this.possibleDay.bind(this)
  }

  renderBullLabel(partida) {
    const bull = this.props.bulls.find((bull) => bull.id === partida?.touroId)

    if (!bull) {
      return ''
    }

    return (
      `${bull.nome}${partida.data
        ? ' - ' + moment(partida.data).format('DD/MM/YYYY')
        : ' - ' + partida.codigo
      } ${_.isEmpty(bull.observacao) ? '' : '(' + bull.observacao + ')'}` || ''
    )
  }

  possibleDay(batchId, day) {
    let batch = this.props.allBatches.filter((b) => b.id === batchId)[0]
    let batchOnNextIatf = this.props.allBatches.filter(
      (b) => b.parentBatchId === batchId
    )[0]
    let result = true

    if (day === 'IATF') {
      if (batch.pastDGFinal) {
        result = true;
      } else if (batch.isResync) {
        result = currentDayIsDG(batch) || currentDayIsDGFinal(batch)
      }
    }

    if (day === 'DG') {
      if (batch.pastDGFinal) {
        result = true;
      } else if (batch.isResync) {
        result = currentDayIsDGFinal(batch)
        if (batchOnNextIatf) {
          result = batchOnNextIatf.pastDGFinal ? true : false;
          result =
            currentDayIsDN(batchOnNextIatf) ||
            currentDayIsIatf(batchOnNextIatf) ||
            currentDayIsDG(batchOnNextIatf) ||
            currentDayIsDGFinal(batchOnNextIatf)
        }
      } else if (batchOnNextIatf && batchOnNextIatf.pastDGFinal) {
        result = true;
      } else {
        result =
          currentDayIsDN(batchOnNextIatf) ||
          currentDayIsIatf(batchOnNextIatf) ||
          currentDayIsDG(batchOnNextIatf) ||
          currentDayIsDGFinal(batchOnNextIatf)
      }
    }
    return result
  }

  async createD0() {
    let errorInputRequired = false
    let payload = {}

    try {
      this.setState({ loading: true })
      payload = {
        ..._.pick(this.state, [
          'codVaca',
          'codLeitorBastao',
          'observacaoD0',
          'ecc',
          'idade_Nov_Pri',
          'responsavelId',
          'dispositivoIntravaginal',
          'dataPartoIndividual',
          'numero_Uso_Disp',
          'racaMatriz',
          'ordemMatriz',
          'clD0',
        ]),
      }
      payload.peso_EM = parseInt(this.state.peso_EM)
      payload.loteId = this.props.batch.id
      payload.dataProcesso = moment.utc()
      payload.omit_from_dg_final = false
      payload.iatf = 0

      let requiredFieldsState = this.requiredFieldsEvaluator()
      let validDates = this.isDateNullOrValid(this.state.dataPartoIndividual)

      if (
        (requiredFieldsState !== null || !validDates) &&
        !payload.codLeitorBastao
      ) {
        this.setState(requiredFieldsState)
        errorInputRequired = true
      } else if (
        (requiredFieldsState !== null || !validDates) &&
        !payload.codVaca
      ) {
        errorInputRequired = false
        payload.codVaca = payload.codLeitorBastao

        this.setState({ codVaca_error: null })

        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.post(payload)

        if (createReq.success) {
          console.log('D0 registered successfully')
          this.setState({
            count: this.state.count + 1,
          })
          const createdCow = createReq.response

          let iatfCows = []
          iatfCows.push(createdCow)
          let savedCows = { [createdCow.id]: { d0: createdCow } }

          this.setState(
            (prevState) => ({
              iatfCows: [...prevState.iatfCows, createdCow],
            }),
            () => {
              this.setState({
                iatfCows,
                savedCows,
                [createdCow.id]: createdCow,
              })
            }
          )

          this.blockBatch(createdCow.id)
          // !this.possibleDay(this.props.batch.id, 'DG') && this.createD0Child(createdCow.id)
        }
      } else {
        errorInputRequired = false

        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.post(payload)

        if (createReq.success) {
          console.log('D0 registered successfully')
          this.setState({
            count: this.state.count + 1,
          })
          const createdCow = createReq.response

          let iatfCows = []
          iatfCows.push(createdCow)
          let savedCows = { [createdCow.id]: { d0: createdCow } }

          this.setState(
            (prevState) => ({
              iatfCows: [...prevState.iatfCows, createdCow],
            }),
            () => {
              this.setState({
                iatfCows,
                savedCows,
                [createdCow.id]: createdCow,
              })
            }
          )

          this.blockBatch(createdCow.id)
          // !this.possibleDay(this.props.batch.id, 'DG') && this.createD0Child(createdCow.id)
        }
      }
    } catch (exception) {
      console.log(`There was an error trying to create D0: ${exception}`)
    } finally {
      this.setState({
        loading: false,
        searchError: false,
        focusDate: false,
        dataPartoIndividual: null,
      })
      if (!errorInputRequired) {
        this.onClean()

        if (
          payload.codLeitorBastao &&
          payload.codVaca === payload.codLeitorBastao
        ) {
          this.codLeitorBastao.current.focus()
        } else {
          this.codVaca.current.focus()
        }
      }
    }
  }

  async deleteCow() {
    if (this.state.iatfCows === null || this.state.iatfCows.length === 0) return

    const allIdsToSameCow = this.state.iatfCows.map((cow) => cow.id)

    this.blockBatch(null)

    const repository = new Repository(AVAILABLE_ENTITIES.D0S)
    const dnRepository = new Repository(AVAILABLE_ENTITIES.DNS)
    const iatfsRepository = new Repository(AVAILABLE_ENTITIES.IATFS)

    allIdsToSameCow.forEach(async (id) => {
      const request = await repository.delete(id)

      const dns = await utils.getWithParam(AVAILABLE_ENTITIES.DNS, 'vacaId', id)

      for (let dn of dns) {
        let dnRequest = await dnRepository.delete(dn.id)
        if (!dnRequest.success) {
          console.log(dnRequest.exception)
        }
      }

      const iatfs = await utils.getWithParam(
        AVAILABLE_ENTITIES.IATFS,
        'vacaId',
        id
      )

      for (let iatf of iatfs) {
        let iatfRequest = await iatfsRepository.delete(iatf.id)
        if (!iatfRequest.success) {
          console.log(iatfRequest.exception)
        }
      }

      let relatedCows = await utils.getWithParam(
        AVAILABLE_ENTITIES.D0S,
        'originalDZero_id',
        id
      )

      for (let relatedCow of relatedCows) {
        let relatedDNS = await utils.getWithParam(
          AVAILABLE_ENTITIES.DNS,
          'vacaId',
          relatedCow.id
        )

        for (let relatedDN of relatedDNS) {
          let relatedDnRequest = await dnRepository.delete(relatedDN.id)
          if (!relatedDnRequest.success) {
            console.log(relatedDnRequest.exception)
          }
        }

        let relatedIATFS = await utils.getWithParam(
          AVAILABLE_ENTITIES.IATFS,
          'vacaId',
          relatedCow.id
        )

        for (let relatedIATF of relatedIATFS) {
          let relatedIATFRequest = await iatfsRepository.delete(relatedIATF.id)
          if (!relatedIATFRequest.success) {
            console.log(relatedIATFRequest.exception)
          }
        }

        let relatedCowRequest = await repository.delete(relatedCow.id)

        if (!relatedCowRequest.success) {
          console.log(relatedCowRequest.exception)
        }
      }

      const newState = {
        iatfCows: [],
        savedIatfs: [],
        savedDGs: [],
        savedCows: [],
        count: this.props.vacas.length,
      }

      if (request.success) {
        console.log(`Delete of cow ${id} successful`)
      } else {
        console.log(`Delete of cow ${id} failed: ${request.exception}`)
      }

      this.setState(newState)
    })
  }

  async createD0Child(id, forcedOmit = false, animalIsDead = false) {
    var cow = this.state[id]
    let descendentBatch = this.props.descendentBatches.filter(
      (db) => db.parentBatchId === cow.loteId
    )[0]
    const cowsRepository = new Repository(AVAILABLE_ENTITIES.D0S)

    let cowPayload = {
      ..._.pick(cow, [
        'codVaca',
        'codLeitorBastao',
        'racaMatriz',
        'ordemMatriz',
        'racaBezerro',
        'sexoBezerro',
        'idade_Nov_Pri',
        'responsavelId',
      ]),
      loteId: descendentBatch.id,
      iatf: cow.iatf + 1,
      omit_from_dg_final: forcedOmit,
      observacaoDG: animalIsDead ? ANIMAL_DIED : cow.observacaoDG,
    }
    cowPayload.dzeroOriginal = cow.dzeroOriginal || cow.id
    cowPayload.dzeroPai = cow.id

    const createCowReq = await cowsRepository.post(cowPayload)

    // let batchPastIatf = this.props.allBatches.filter(db => db.parentBatchId === cowPayload.loteId)[0];
    // let allBatches = this.props.allBatches
    // let iatfDay = false
    // let dgDay = false
    // let dgFinal = false

    // if (!batchPastIatf) {
    //   let lote = allBatches.filter(ab => ab.id === cowPayload.loteId)[0]
    //   if ((currentDayIsIatf(lote) ||
    //     currentDayIsRessinc(lote)) && !currentDayIsDG(lote)) {
    //     iatfDay = true
    //   }
    //   if (currentDayIsDG(lote) && (!lote.pastDGFinal || !lote.pastDG)) {
    //     dgDay = true
    //   }

    //   if (lote.pastDG) {
    //     dgFinal = true
    //   }
    // }

    if (createCowReq.success) {
      let newCow = createCowReq.response
      let newIatfCows = this.state.iatfCows

      if (!forcedOmit && !animalIsDead) {
        newIatfCows.push(newCow)
        this.setState({ iatfCows: newIatfCows, [newCow.id]: newCow })
      }

      console.log(
        `There was a problem transposing cow to resync batch: ${createCowReq.exception}`
      )
      // }
    }
  }

  handleSearch(e) {
    const val = e.target.value
    if (_.isEmpty(val)) {
      this.setState({ selectedCows: this.state.allCows })
    } else {
      const selectedCows = this.state.allCows.filter(
        (x) => x.codVaca.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      this.setState({ selectedCows })
    }
  }

  handleSwitchChange = (event) => {
    this.setState({ checked: event.target.checked })
  }

  async handleSave() {
    if (this.state.checked && this.state.codVaca != null) {
      const val = this.state.codVaca
      let searchError = null
      if (this.state.selectedCows.map((m) => m.codVaca).indexOf(val) >= 0) {
        this.setState({
          searchError:
            'Já existe uma matriz com esse nome cadastrada nesse lote.',
        })
        return
      } else {
        const outOfBatch = await utils.farmWideSearch(
          val,
          this.state.batchId,
          this.props.farm.id
        )

        if (outOfBatch.msg) {
          searchError = outOfBatch.msg
          if (searchError === 'Matriz não encontrada.') {
            this.createD0()
            this.setState({ renderIndex: 0 })
            return
          }
          if (searchError.indexOf('não faz parte deste Lote') >= 0) {
            searchError = searchError.replace(
              `A matriz ${this.state.codVaca} não faz parte deste Lote.`,
              ``
            )
          }
          this.setState({
            searchError,
          })
        } else {
          this.createD0()
          this.setState({ renderIndex: 0 })
        }
      }
    } else {
      this.createD0()
      this.setState({ renderIndex: 0 })
    }
  }

  onClean() {
    this.setState({
      codVaca: null,
      observacaoD0: null,
      peso_EM: null,
      clD0: this.props.clD0.filter((x) => x.valor === 'Não Avaliado')[0].id,
      ..._.pick(this.props.initialValues, [
        'idade_Nov_Pri',
        'responsavelId',
        'dataPartoIndividual',
        'racaMatriz',
        'ordemMatriz',
      ]),
    })
  }

  onEnterPress = (e) => {
    if (this.props.users[0].roles[0]?.name !== 'Cliente (somente visualização)') {
      if (e.key === 'Enter' && e.shiftKey === false) {
        e.preventDefault()
        this.handleSave()
      }
    }
  }

  fullClean() {
    this.setState({
      codVaca: null,
      observacaoD0: null,
      peso_EM: null,
      ecc: null,
      idade_Nov_Pri: null,
      responsavelId: null,
      dispositivoIntravaginal: null,
      dataPartoIndividual: null,
      numero_Uso_Disp: null,
      racaMatriz: null,
      ordemMatriz: null,
      clD0: null,
    })
  }

  @track({ action: 'Finalizou o Incluir matriz' })
  async finalize(downloadBackup) {
    await this.blockBatch().then(() => {
      if (this.props.farm.retiro) {
        this.props.history.push(
          `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
        )
      } else {
        this.props.history.push(`/farms/${this.props.farm.id}`)
      }

      sync.downloadProofOfManagement({
        values: ['lotes', 'd0s', 'dns', 'iatfs'],
        text: 'Comprovante de manejo Cadastro de Matriz Adicional - ' +
          formatName(this.props.batch.nomeLote, LOTE) +
          ' - ' +
          formatName(this.props.farm.nome, FAZENDA) +
          ' - ' +
          moment.utc().valueOf() +
          '.progerar',
        batchIds: this.props.match.params.id.split(';')
      })
    })
  }

  async createIatf(id) {
    const cow = this.state[id]
    let nextBatch = this.props.allBatches.filter(
      (b) => b.parentBatchId === cow.loteId
    )

    let success = true
    if (cow) {
      this.setState({
        savedIatfs: [...this.state.savedIatfs, cow.id],
      })

      let payload = {
        vacaId: cow.id,
        loteId: cow.loteId,
        ..._.pick(cow, [
          'gnhr',
          'cioRetirada',
          'temperamento',
          'partidaId',
          'usuarioId',
        ]),
      }
      payload.ecc = cow.eccIATF
      payload.observacao = cow.observacaoIATF

      let missingRequiredFields = false
      const requiredFields =
        _.isEmpty(payload.observacao) ||
          (payload.observacao !== ANIMAL_NOT_PRESENT_TO_MANAGEMENT &&
            payload.observacao !== FOUND_DEVICE_INSIDE_COW &&
            payload.observacao !== ANIMAL_NOT_INSEMINATED &&
            payload.observacao !== ANIMAL_DIED &&
            payload.observacao !== ANIMAL_DISCARDED)
          ? ['partidaId', 'usuarioId']
          : []

      let state = {
        ..._.reduce(
          requiredFields,
          (accumulator, field) => {
            if (_.isEmpty(payload[field])) {
              missingRequiredFields = true
              return {
                ...accumulator,
                [`${field}_error`]: REQUIRED_FIELD_MESSAGES.DEFAULT,
              }
            }
            return accumulator
          },
          {}
        ),
      }

      if (missingRequiredFields) {
        this.setState({
          [cow.id]: {
            ...this.state[cow.id],
            ...state,
          },
          savedIatfs: this.state.savedIatfs.filter((x) => x !== cow.id),
        })
      } else {
        const repository = new Repository(AVAILABLE_ENTITIES.IATFS)
        const existingRequest = await repository.getByParam('vaca_id', cow.id)
        if (existingRequest.success) {
          const existingEntry = existingRequest.response
          if (existingEntry.length > 0) {
            const updateRequest = await repository.update({
              ...payload,
              id: existingEntry[0].id,
            })
            if (!updateRequest.success) {
              console.log(
                `There was a problem trying to update entry for cow ${cow.id}: ${updateRequest.exception}`
              )
              success = false
            }
          } else {
            const createRequest = await repository.post(payload)
            if (!createRequest.success) {
              console.log(
                `There was a problem trying to create entry for cow ${cow.id}: ${createRequest.exception}`
              )
              success = false
            } else {
              if (nextBatch.length > 0 && !this.possibleDay(cow.loteId, 'DG')) {
                this.createD0Child(id)
              }
            }
          }
        } else {
          console.log(
            `There was a problem trying to search for existing entry for cow: ${cow.id}: ${existingRequest.exception}`
          )
          success = false
        }

        if (_.isEmpty(cow.codVaca)) {
          success = false
        }

        if (!success) {
          this.setState({
            [cow.id]: state,
            savedIatfs: this.state.savedIatfs.filter((x) => x !== cow.id),
          })
        }
      }
    }

    return success
  }

  async createDG(id) {
    const cow = this.state[id]
    let descendentBatch = this.props.descendentBatches.filter(
      (db) => db.parentBatchId === cow.loteId
    )[0]

    let success = true
    if (
      (!_.isEmpty(cow.condicaoDG) ||
        cow.observacaoDG === ANIMAL_NOT_PRESENT ||
        cow.observacaoDG === ANIMAL_DIED ||
        cow.observacaoDG === ANIMAL_UNDIAGNOSED) &&
      cow !== null
    ) {
      this.setState({
        savedDGs: [...this.state.savedDGs, id],
      })
      if (
        _.isEmpty(cow.condicaoDG) &&
        (_.isEmpty(cow.observacaoDG) ||
          (cow.observacaoDG !== ANIMAL_NOT_PRESENT &&
            cow.observacaoDG !== ANIMAL_DIED &&
            cow.observacaoDG !== ANIMAL_UNDIAGNOSED))
      ) {
        this.setState({
          [id]: {
            ...cow,
            condicaoDG_error: REQUIRED_FIELD_MESSAGES.DEFAULT,
          },
        })
      } else {
        let missingRequiredFields = false
        let state = {}

        if (cow.isPrenhe || cow.isPrenheTouro) {
          const requiredFields =
            _.isEmpty(cow.observacaoDG) ||
              (cow.observacaoDG !== ANIMAL_NOT_PRESENT &&
                cow.observacaoDG !== ANIMAL_DIED &&
                cow.observacaoDG !== ANIMAL_UNDIAGNOSED)
              ? ['tipoPrenhe']
              : []

          state = {
            ..._.reduce(
              requiredFields,
              (accumulator, field) => {
                if (_.isEmpty(cow[field])) {
                  missingRequiredFields = true
                  return {
                    ...accumulator,
                    [`${field}_error`]: REQUIRED_FIELD_MESSAGES.DEFAULT,
                  }
                }
                return accumulator
              },
              {}
            ),
          }
        }

        if (missingRequiredFields) {
          success = false
          this.setState({
            [cow.id]: {
              ...this.state[cow.id],
              ...state,
            },
          })
        } else {
          const repository = new Repository(AVAILABLE_ENTITIES.D0S)
          const updateRequest = await repository.update({
            ..._.clone(cow),
            // omit_from_dg_final,
          })
          if (updateRequest.success) {
            if (
              descendentBatch &&
              // cow.observacaoDG !== ANIMAL_NOT_PRESENT &&
              cow.observacaoDG !== ANIMAL_DIED
            ) {
              if ((!cow.isPrenhe || !cow.isPrenheTouro) && !cow.isDescartePrenhe) {
                this.createD0Child(id)
              } else {
                this.createD0Child(id, true)
              }
            }

            if (cow.observacaoDG === ANIMAL_DIED) {
              this.createD0Child(id, false, true)
            }

            if (cow.iatf > 1) {
              this.omitCows(cow.loteId)
            } else {
              this.omitCows(descendentBatch.id)
            }

            console.log('Cow updated successfully')
          } else {
            console.log(
              `There was a problem updating cow ${id}:${cow.codVaca}: ${updateRequest.exception}`
            )
            success = false
          }
        }

        if (!success) {
          this.setState({
            savedDGs: this.state.savedDGs.filter((x) => x !== id),
          })
        }
      }
    }
  }

  async blockBatch(d0Id = null) {
    const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)
    const blockBatchRequest = await repository.update({
      id: this.props.batch.id,
      matrizBloqueada: d0Id,
    })
    if (blockBatchRequest.success) {
      console.log('Cow updated successfully')
    }
  }

  async omitCows(lastBatchId) {
    let lastBatch = this.props.allBatches.filter((b) => b.id === lastBatchId)
    let previousCows = []
    let success = true
    const previousBatches =
      lastBatch[0].parentBatchId &&
      (await utils.getWithParam(
        AVAILABLE_ENTITIES.BATCHES,
        'id',
        Q.oneOf(
          _.compact(_.uniq(lastBatch.map((batch) => batch.parentBatchId)))
        )
      ))

    previousCows =
      previousBatches &&
      _.filter(
        await utils.getWithParam(
          AVAILABLE_ENTITIES.D0S,
          'batch_id',
          Q.oneOf(previousBatches.map((batch) => batch.id))
        ),
        (vaca) => !vaca.omit_from_dg_final
      )

    const repository = new Repository(AVAILABLE_ENTITIES.D0S)

    for (let vaca of previousCows) {
      let toUpdateCow = this.state[vaca.id]
      if (toUpdateCow) {
        const omit_from_dg_final = !toUpdateCow.isPrenhe || !toUpdateCow.isPrenheTouro
        const updateRequest = await repository.update({
          ..._.clone(toUpdateCow),
          omit_from_dg_final,
        })
        if (updateRequest.success) {
          console.log('Cow updated successfully')
        } else {
          console.log(
            `There was a problem updating cow ${toUpdateCow.id}:${toUpdateCow.codVaca}: ${updateRequest.exception}`
          )
          success = false
        }

        if (success) {
          const newCow = _.find(this.props.vacas, { dzeroPai: vaca.id })
          if (!newCow) {
            console.log(`Could not find cow`)
          } else {
            const updateNewD0Req = await repository.update({
              id: newCow.id,
              omit_from_dg_final:
                toUpdateCow.isPrenhe || toUpdateCow.isDescartePrenhe || toUpdateCow.isDescartePrenhe,
            })

            if (updateNewD0Req.success) {
              console.log('Cow updated successfully')
            } else {
              console.log(
                `There was a problem updating cow ${newCow.id}:${newCow.codVaca}: ${updateRequest.exception}`
              )
              success = false
            }
          }
        }
      }
    }
  }

  renderTitle() {
    return (
      <Fragment>
        Protocolo: {this.props.protocol.name}
        <br />
        Manejo: Cadastro de Matriz Adicional
      </Fragment>
    )
  }

  removeFromSave(vacaId) {
    if (this.state.salvas.indexOf(vacaId) >= 0) {
      this.setState({ salvas: this.state.salvas.filter((x) => x !== vacaId) })
    }
  }

  renderColumnLabel(row, rowItem, prop, label) {
    return (
      this.props[prop] &&
      row[rowItem] &&
      this.props[prop].find((x) => x.id === row[rowItem])[label]
    )
  }

  updateCondition(vacaId, value) {
    const isVazia = value && value.valor === 'Vazia'
    const isPrenhe = value && value.valor === 'Prenhe'
    const isPrenheTouro = value && value.valor === 'Prenhe Touro'
    const isDescartePrenhe = value && value.valor === 'Descarte Prenhe'
    const isDescarteVazia = value && value.valor === 'Descarte Vazia'

    return { isVazia, isPrenhe, isPrenheTouro, isDescartePrenhe, isDescarteVazia }
  }

  renderField(row, index) {
    return (
      <>
        {this.possibleDay(row.loteId, 'IATF') ? (
          <>
            <h3>{`Inseminação: IATF ${index + 1}`}</h3>
            <div className='grid grid-iatf'>
              <div className='grid-item p-12 t-6 d-6'>
                <Autocomplete
                  id={`semen_${index}`}
                  options={this.props.partidas}
                  disabled={this.state.savedIatfs.indexOf(row.id) >= 0}
                  value={this.state[row.id]?.partidaId || null}
                  onChange={(e, value) => {
                    this.setState({
                      [row.id]: {
                        ...row,
                        ...this.state[row.id],
                        partidaId: value && value.id,
                        codVaca_error: _.isEmpty(this.state[row.id].codVaca)
                          ? REQUIRED_FIELD_MESSAGES.DEFAULT
                          : null,
                        eccIATF_error: true
                          ? _.isEmpty(value) || _.isEmpty(value.id)
                            ? REQUIRED_FIELD_MESSAGES.DEFAULT
                            : null
                          : null,
                        partidaId_error: value
                          ? null
                          : REQUIRED_FIELD_MESSAGES.DEFAULT,
                        observacaoIATF:
                          this.state[row.id].observacaoIATF === ANIMAL_DISCARDED
                            ? null
                            : this.state[row.id].observacaoIATF,
                      },
                      // savedCows: this.state.savedCows.filter(
                      //   element => element !== row.id
                      // )
                    })
                  }}
                  onKeyDown={
                    (this.state[row.id]?.partidaId ||
                      this.state[row.id]?.partidaId_value) &&
                    this.state[row.id]?.partidaId.length > 3 &&
                    ((e) => this.onEnterPress(row.id, e))
                  }
                  getOptionLabel={this.autocompleteOptionLabelUsingCustomHandlerSavingID(
                    this.props.partidas,
                    this.renderBullLabel
                  )}
                  useDefaultOptionSelected
                  label='Sêmen'
                  error={!_.isEmpty(this.state[row.id]?.partidaId_error)}
                  helperText={this.state[row.id]?.partidaId_error}
                />
              </div>

              <div className='grid-item p-12 t-6 d-6'>
                <Autocomplete
                  id={`inseminador_${index}`}
                  options={this.props.users}
                  disabled={this.state.savedIatfs.indexOf(row.id) >= 0}
                  value={this.state[row.id]?.usuarioId || null}
                  onChange={(e, value) => {
                    this.setState({
                      [row.id]: {
                        ...row,
                        ...this.state[row.id],
                        usuarioId: value && value.id,
                        codVaca_error:
                          _.isEmpty(this.state[row.id].codVaca) &&
                          REQUIRED_FIELD_MESSAGES.DEFAULT,
                        eccIATF_error: true
                          ? _.isEmpty(value) || _.isEmpty(value.id)
                            ? REQUIRED_FIELD_MESSAGES.DEFAULT
                            : null
                          : null,
                        usuarioId_error: value
                          ? null
                          : REQUIRED_FIELD_MESSAGES.DEFAULT,
                      },
                      // savedCows: this.state.savedCows.filter(
                      //   element => element !== row.id
                      // )
                    })
                  }}
                  onKeyDown={
                    (this.state.iatfCows[index]?.usuarioId ||
                      this.state.iatfCows[index]?.usuarioId_value) &&
                    this.state.iatfCows[index]?.usuarioId.length > 3 &&
                    ((e) => this.onEnterPress(row.id, e))
                  }
                  getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                    this.props.users,
                    'nomeCompleto'
                  )}
                  useDefaultOptionSelected
                  label='Inseminador'
                  error={!_.isEmpty(this.state[row.id]?.usuarioId_error)}
                  helperText={this.state[row.id]?.usuarioId_error}
                />
              </div>

              <div className='grid-item p-12 t-6 d-3'>
                <Autocomplete
                  id={`ecc_${index}`}
                  options={this.props.ecc}
                  disabled={this.state.savedIatfs.indexOf(row.id) >= 0}
                  value={this.state[row.id]?.eccIATF || null}
                  onChange={(e, value) => {
                    this.setState({
                      [row.id]: {
                        ...row,
                        ...this.state[row.id],
                        eccIATF: value && value.id,
                      },
                      // savedCows: this.state.savedCows.filter(
                      //   element => element !== row.id
                      // )
                    })
                  }}
                  onKeyDown={
                    (this.state.iatfCows[index]?.eccIATF ||
                      this.state.iatfCows[index]?.eccIATF_value) &&
                    this.state.iatfCows[index]?.eccIATF.length > 3 &&
                    ((e) => this.onEnterPress(row.id, e))
                  }
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.ecc
                  )}
                  useDefaultOptionSelected
                  label='ECC'
                />
              </div>

              <div className='grid-item p-12 t-6 d-3'>
                <Autocomplete
                  id={`cio_${index}`}
                  options={this.props.cio}
                  disabled={this.state.savedIatfs.indexOf(row.id) >= 0}
                  value={this.state[row.id]?.cioRetirada || null}
                  onChange={(e, value) => {
                    this.setState({
                      [row.id]: {
                        ...row,
                        ...this.state[row.id],
                        cioRetirada: value && value.id,
                      },
                      // savedCows: this.state.savedCows.filter(
                      //   element => element !== row.id
                      // )
                    })
                  }}
                  onKeyDown={
                    (this.state.iatfCows[index]?.cioRetirada ||
                      this.state.iatfCows[index]?.cioRetirada_value) &&
                    this.state.iatfCows[index]?.cioRetirada.length > 3 &&
                    ((e) => this.onEnterPress(row.id, e))
                  }
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.cio
                  )}
                  useDefaultOptionSelected
                  label='Cio'
                />
              </div>

              <div className='grid-item p-12 t-6 d-3'>
                <Autocomplete
                  id={`gnhr_${index}`}
                  options={this.props.gnhr}
                  disabled={this.state.savedIatfs.indexOf(row.id) >= 0}
                  value={this.state[row.id]?.gnhr || null}
                  onChange={(e, value) => {
                    this.setState({
                      [row.id]: {
                        ...row,
                        ...this.state[row.id],
                        gnhr: value && value.id,
                      },
                      // savedCows: this.state.savedCows.filter(
                      //   element => element !== row.id
                      // )
                    })
                  }}
                  onKeyDown={
                    (this.state.iatfCows[index]?.gnhr ||
                      this.state.iatfCows[index]?.gnhr_value) &&
                    this.state.iatfCows[index]?.gnhr.length > 3 &&
                    ((e) => this.onEnterPress(row.id, e))
                  }
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.gnhr
                  )}
                  useDefaultOptionSelected
                  label='GnRH'
                />
              </div>

              <div className='grid-item p-12 t-6 d-3'>
                <Autocomplete
                  id={`temperamento_${index}`}
                  options={this.props.temperamento}
                  disabled={this.state.savedIatfs.indexOf(row.id) >= 0}
                  value={this.state[row.id]?.temperamento || null}
                  onChange={(e, value) => {
                    this.setState({
                      [row.id]: {
                        ...row,
                        ...this.state[row.id],
                        temperamento: value && value.id,
                      },
                      // savedCows: this.state.savedCows.filter(
                      //   element => element !== row.id
                      // )
                    })
                  }}
                  onKeyDown={
                    (this.state.iatfCows[index]?.temperamento ||
                      this.state.iatfCows[index]?.temperamento_value) &&
                    this.state.iatfCows[index]?.temperamento.length > 3 &&
                    ((e) => this.onEnterPress(row.id, e))
                  }
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.temperamento
                  )}
                  useDefaultOptionSelected
                  label='Temperamento'
                />
              </div>

              <div className='grid-item p-12 t-12 d-9'>
                <Autocomplete
                  id={`obs_${index}`}
                  label='Observação'
                  options={obsIATFOptions}
                  disabled={this.state.savedIatfs.indexOf(row.id) >= 0}
                  onChange={(e, value) => {
                    let state = {
                      ...row,
                      ...this.state[row.id],
                      observacaoIATF: value && value,
                    }
                    let cowsWithBlockingObs = this.state.cowsWithBlockingObs
                    if (
                      value === ANIMAL_NOT_PRESENT_TO_MANAGEMENT ||
                      value === ANIMAL_NOT_INSEMINATED ||
                      value === ANIMAL_DISCARDED
                    ) {
                      state.partidaId = null
                      state.usuarioId = null
                      state.usuarioId_error = null
                      state.partidaId_error = null
                      cowsWithBlockingObs.push(this.state[row.id].id)
                    } else {
                      cowsWithBlockingObs = cowsWithBlockingObs.filter(
                        (cwbo) => cwbo !== row.id
                      )
                    }
                    this.setState({
                      [row.id]: state,
                      cowsWithBlockingObs,
                    })
                  }}
                  onKeyDown={
                    (this.state.iatfCows[index].observacaoIATF ||
                      this.state.iatfCows[index].observacaoIATF_value) &&
                    this.state.iatfCows[index].observacaoIATF.length > 3 &&
                    ((e) => this.onEnterPress(row.id, e))
                  }
                  value={this.state.iatfCows[index].observacaoIATF || null}
                  openField
                />
              </div>

              <div
                className={`grid-item p-12 t-3`}
                style={{ textAlign: 'right', paddingTop: '10px' }}
              >
                <Button
                  style={{ width: '100%' }}
                  label='Ok'
                  onClick={() => {
                    this.state.savedIatfs.indexOf(row.id) >= 0
                      ? console.log(`There's nothing to save`)
                      : this.createIatf(row.id)
                    this.setState({
                      renderIndex:
                        this.state[row.id]?.observacaoIATF ===
                          ANIMAL_DISCARDED ||
                          (!this.state.savedIatfs.indexOf(row.id) >= 0 &&
                            !this.possibleDay(row.loteId, 'DG'))
                          ? this.props.allBatches.length
                          : this.props.allBatches.length - 1 === index &&
                            this.possibleDay(row.loteId, 'DG')
                            ? index
                            : index + 1,
                    })
                  }}
                  disabled={this.state.savedIatfs.indexOf(row.id) >= 0}
                />
              </div>
            </div>
            {this.state.savedIatfs.indexOf(row.id) >= 0 ? (
              <Alert severity='success'>Ok!</Alert>
            ) : (
              <Alert severity='warning'>
                Este Manejo deve ser preenchido para que a Matriz seja incluida
                corretamente.
              </Alert>
            )}
            <br />
          </>
        ) : (
          <Alert severity='success'>
            Agora que a Matriz está preenchida basta finalizar e continuar os
            manejos junto a Iatf atual do lote.
          </Alert>
        )}

        {this.possibleDay(row.loteId, 'DG') && (
          <>
            <h3>{`DG: IATF ${index + 1}`}</h3>
            <div className='grid grid-iatf'>
              <div className='grid-item p-12 t-5'>
                <Autocomplete
                  id={`condicaoDG_${index}`}
                  options={this.props.condicaoDG}
                  value={this.state[row.id]?.condicaoDG || null}
                  onChange={(e, value) => {
                    const {
                      isVazia,
                      isPrenhe,
                      isPrenheTouro,
                      isDescartePrenhe,
                      isDescarteVazia,
                    } = this.updateCondition(row.id, value)

                    this.setState({
                      [row.id]: {
                        ...row,
                        ...this.state[row.id],
                        condicaoDG: value && value.id,
                        condicaoDG_error:
                          !_.isEmpty(value) && !_.isEmpty(value.id)
                            ? null
                            : REQUIRED_FIELD_MESSAGES.DEFAULT,
                        tipoPrenhe:
                          isVazia || isDescarteVazia
                            ? null
                            : this.props.tipoPrenhe.filter(
                              (tipo) => tipo.valor === 'Normal'
                            )[0].id,
                        clVazia:
                          isVazia || isDescarteVazia
                            ? this.props.clVazia.filter(
                              (x) => x.valor === 'Não Avaliado'
                            )[0].id
                            : null,
                        tipoPrenhe_error: !_.isEmpty(value) && null,
                        isVazia,
                        isPrenhe,
                        isPrenheTouro,
                        isDescartePrenhe,
                        isDescarteVazia,
                        observacaoDG:
                          this.state[row.id].observacaoDG ===
                            ANIMAL_NOT_PRESENT ||
                            this.state[row.id].observacaoDG === ANIMAL_DIED ||
                            this.state[row.id].observacaoDG === ANIMAL_UNDIAGNOSED
                            ? null
                            : this.state[row.id].observacaoDG,
                        omit_from_dg_final: false,
                        dataDg: moment.utc(),
                      },
                    })
                    // this.removeFromSave(row.id)
                  }}
                  disabled={
                    !(this.state.savedIatfs.indexOf(row.id) >= 0) ||
                    this.state[row.id]?.observacaoIATF === ANIMAL_DISCARDED ||
                    this.state.savedDGs.indexOf(row.id) >= 0
                  }
                  onKeyDown={
                    (this.state.iatfCows[index]?.condicaoDG ||
                      this.state.iatfCows[index]?.condicaoDG_value) &&
                    this.state.iatfCows[index]?.condicaoDG.length > 3 &&
                    ((e) => this.onEnterPress(row.id, e))
                  }
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.condicaoDG
                  )}
                  useDefaultOptionSelected
                  label='Diagnóstico'
                  error={!_.isEmpty(this.state[row.id]?.condicaoDG_error)}
                  helperText={this.state[row.id]?.condicaoDG_error}
                />
              </div>

              <div
                className={
                  `grid-item p-12 t-` +
                  (this.state.iatfCows[index]?.condicaoDG == null ||
                    row.isDescartePrenhe
                    ? 4
                    : 2)
                }
              >
                <Autocomplete
                  id={`eccDG_${index}`}
                  options={this.props.ecc}
                  onChange={(e, value) => {
                    this.setState({
                      [row.id]: {
                        ...row,
                        ...this.state[row.id],
                        eccDG: value && value.id,
                        dataDg: moment.utc(),
                      },
                    })
                    // this.removeFromSave(row.id)
                  }}
                  disabled={
                    !(this.state.savedIatfs.indexOf(row.id) >= 0) ||
                    this.state[row.id]?.observacaoIATF === ANIMAL_DISCARDED ||
                    this.state.savedDGs.indexOf(row.id) >= 0
                  }
                  onKeyDown={
                    (this.state.iatfCows[index]?.eccDG ||
                      this.state.iatfCows[index]?.eccDG_value) &&
                    this.state.iatfCows[index]?.eccDG.length > 3 &&
                    ((e) => this.onEnterPress(row.id, e))
                  }
                  value={this.state[row.id]?.eccDG || null}
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.ecc
                  )}
                  useDefaultOptionSelected
                  label='ECC'
                />
              </div>

              {this.state[row.id]?.isPrenhe ||
                this.state[row.id]?.isPrenheTouro ||
                this.state[row.id]?.isDescartePrenhe ? (
                <div className={`grid-item p-12 t-3`}>
                  <Autocomplete
                    id={`tipoPrenhe_${index}`}
                    options={this.props.tipoPrenhe}
                    onChange={(e, value) => {
                      this.setState({
                        [row.id]: {
                          ...row,
                          ...this.state[row.id],
                          tipoPrenhe: value && value.id,
                          tipoPrenhe_error:
                            _.isEmpty(value) && REQUIRED_FIELD_MESSAGES.DEFAULT,
                          dataDg: moment.utc(),
                        },
                      })
                      // this.removeFromSave(row.id)
                    }}
                    onKeyDown={
                      (this.state.iatfCows[index]?.tipoPrenhe ||
                        this.state.iatfCows[index]?.tipoPrenhe_value) &&
                      this.state.iatfCows[index]?.tipoPrenhe.length > 3 &&
                      ((e) => this.onEnterPress(row.id, e))
                    }
                    value={this.state[row.id]?.tipoPrenhe || null}
                    getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                      this.props.tipoPrenhe
                    )}
                    disabled={
                      !(this.state.savedIatfs.indexOf(row.id) >= 0) ||
                      this.state[row.id]?.observacaoIATF === ANIMAL_DISCARDED ||
                      this.state.savedDGs.indexOf(row.id) >= 0
                    }
                    useDefaultOptionSelected
                    label='Tipo'
                    error={!_.isEmpty(this.state[row.id]?.tipoPrenhe_error)}
                    helperText={this.state[row.id]?.tipoPrenhe_error}
                  />
                </div>
              ) : this.state[row.id]?.isVazia ||
                this.state[row.id]?.isDescarteVazia ? (
                <div className={`grid-item p-12 t-3`}>
                  <Autocomplete
                    id={`clVazia_${index}`}
                    options={this.props.clVazia}
                    onChange={(e, value) => {
                      this.setState({
                        [row.id]: {
                          ...row,
                          ...this.state[row.id],
                          clVazia: value && value.id,
                          dataDg: moment.utc(),
                        },
                      })
                      // this.removeFromSave(RowingRounded.id)
                    }}
                    disabled={
                      !(this.state.savedIatfs.indexOf(row.id) >= 0) ||
                      this.state[row.id]?.observacaoIATF === ANIMAL_DISCARDED ||
                      this.state.savedDGs.indexOf(row.id) >= 0
                    }
                    onKeyDown={
                      (this.state.iatfCows[index]?.clVazia ||
                        this.state.iatfCows[index]?.clVazia_value) &&
                      this.state.iatfCows[index]?.clVazia.length > 3 &&
                      ((e) => this.onEnterPress(row.id, e))
                    }
                    value={this.state[row.id].clVazia || null}
                    getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                      this.props.clVazia
                    )}
                    useDefaultOptionSelected
                    label='Presença de CL'
                  />
                </div>
              ) : (
                <div className={`grid-item p-12 t-2`}></div>
              )}

              <div className={`grid-item p-12 t-9`}>
                <Autocomplete
                  id={`observacaoDG_${index}`}
                  options={obsDGOptions}
                  disabled={
                    !(this.state.savedIatfs.indexOf(row.id) >= 0) ||
                    this.state[row.id]?.observacaoIATF === ANIMAL_DISCARDED ||
                    this.state.savedDGs.indexOf(row.id) >= 0
                  }
                  onChange={(e, value) => {
                    let state = {
                      ...row,
                      ...this.state[row.id],
                      observacaoDG: value,
                      dataDg: moment.utc(),
                    }
                    let cowsWithBlockingObs = this.state.cowsWithBlockingObs
                    if (
                      value === ANIMAL_NOT_PRESENT ||
                      value === ANIMAL_DIED ||
                      value === ANIMAL_UNDIAGNOSED
                    ) {
                      state.isPrenhe = null
                      state.isPrenheTouro = null
                      state.isDescartePrenhe = null
                      state.isVazia = null
                      state.isDescarteVazia = null
                      state.condicaoDG = null
                      state.eccDG = null
                      state.tipoPrenhe = null
                      state.clVazia = null
                      state.dispositivo_d0Resync = null
                      state.usoDispositivo_d0Resync = null
                      state.peso_d0resync = null
                      state.condicaoDG_error = null
                    }

                    if (value === ANIMAL_DIED) {
                      cowsWithBlockingObs.push(row.id)
                    } else {
                      cowsWithBlockingObs.filter((cwbo) => cwbo !== row.id)
                    }

                    this.setState({
                      [row.id]: state,
                      cowsWithBlockingObs,
                    })
                    // this.removeFromSave(row.id)
                  }}
                  onKeyDown={
                    (this.state.iatfCows[index]?.observacaoDG ||
                      this.state.iatfCows[index]?.observacaoDG_value) &&
                    this.state.iatfCows[index]?.observacaoDG.length > 3 &&
                    ((e) => this.onEnterPress(row.id, e))
                  }
                  label='Observações'
                  value={this.state[row.id]?.observacaoDG || null}
                  openField
                />
              </div>

              <div
                className={`grid-item p-12 t-3`}
                style={{ textAlign: 'right', paddingTop: '10px' }}
              >
                <Button
                  label='Ok'
                  style={{ width: '100%', marginTop: '8px' }}
                  disabled={
                    !(this.state.savedIatfs.indexOf(row.id) >= 0) ||
                    this.state[row.id]?.observacaoIATF === ANIMAL_DISCARDED ||
                    this.state.savedDGs.indexOf(row.id) >= 0
                  }
                  onClick={() => {
                    this.createDG(row.id)
                    this.setState((prevState) => ({
                      renderIndex:
                        prevState[row.id].condicaoDG === idConditionDG.pregnant ||
                          prevState[row.id].condicaoDG === idConditionDG.pregnantDiscard ||
                          prevState[row.id].condicaoDG === idConditionDG.pregnantBull ||
                          prevState.cowsWithBlockingObs.length !== 0 ||
                          (!prevState.savedDGs.includes(row.id) &&
                            this.possibleDay(row.loteId, 'IATF') &&
                            this.possibleDay(row.loteId, 'DG') &&
                            this.props.allBatches.length === 2)
                          ? this.props.allBatches.length
                          : this.props.allBatches.length - 1 === index
                            ? index + 1
                            : prevState.renderIndex,
                    }))
                  }}
                />
              </div>
            </div>
            {this.state[row.id]?.observacaoIATF === ANIMAL_DISCARDED ||
              this.state.savedDGs.indexOf(row.id) >= 0 ? (
              <Alert severity='success'>Ok!</Alert>
            ) : !(this.state.savedIatfs.indexOf(row.id) >= 0) ? (
              <Alert severity='warning'>Manejo anterior pendente.</Alert>
            ) : (
              <Alert severity='warning'>
                Este Manejo deve preenchido para que a Matriz seja incluida
                corretamente.
              </Alert>
            )}
            <br />
          </>
        )}
      </>
    )
  }

  getIatfTableColumns() {
    return [
      {
        name: 'Manejos',
        type: CELL_TYPES.FIELD,
        field: (row, index) => this.renderField(row, index),
        grid: 12,
      },
    ]
  }

  getIatfTableData() {
    let iatfCows = Object.entries(this.state.iatfCows)
    let colectionsiatfCows = []
    iatfCows.map((x) => colectionsiatfCows.push(x[1]))

    return colectionsiatfCows
  }

  getTableColumnData() {
    return [
      {
        name: 'Matriz (ID)',
        type: CELL_TYPES.TEXT,
        grid: 2,
        label: (row) => row.codVaca,
      },
      {
        name: 'Raça',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(row, 'racaMatriz', 'racaMatriz', 'descricao'),
      },
      {
        name: 'Ordem',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(
            row,
            'ordemMatriz',
            'ordemMatriz',
            'descricao'
          ),
      },
      {
        name: 'ECC',
        type: CELL_TYPES.TEXT,
        label: (row) => this.renderColumnLabel(row, 'ecc', 'ecc', 'valor'),
      },
      {
        name: 'Peso',
        type: CELL_TYPES.TEXT,
        label: (row) => row.peso_EM,
      },
      {
        name: 'Dispositivo',
        type: CELL_TYPES.TEXT,
        grid: 2,
        label: (row) =>
          this.renderColumnLabel(
            row,
            'dispositivoIntravaginal',
            'implantes',
            'valor'
          ),
      },
      {
        name: 'Uso',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(
            row,
            'numero_Uso_Disp',
            'usoImplantes',
            'valor'
          ),
      },
      {
        name: 'Idade',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(row, 'idade_Nov_Pri', 'idadeMatriz', 'valor'),
      },
      {
        name: 'CL',
        type: CELL_TYPES.TEXT,
        label: (row) => this.renderColumnLabel(row, 'clD0', 'clD0', 'valor'),
      },
    ]
  }

  getButtons() {
    return [
      {
        onClick: () => this.setState({ showingFinalizeConfirmation: true }),
        icon: <DoneIcon />,
        label: 'Finalizar',
        disabled:
          this.state.iatfCows.length === this.props.allBatches.length ||
            this.state.renderIndex === this.props.allBatches.length
            ? false
            : true,
      },
    ]
  }

  getPaths() {
    const extraPath = this.props.farm.retiro && {
      route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`,
      label: formatName(this.props.corral.nome, RETIRO),
    }

    return _.compact([
      {
        route: `/farms/${this.props.farm.id}`,
        label: formatName(this.props.farm.nome, FAZENDA),
      },
      extraPath,
      {
        route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}/batches/${this.props.batch.id}`,
        label: formatName(this.props.batch.nomeLote, LOTE),
      },
      {
        label: 'Incluir Matriz',
      },
    ])
  }

  renderHeader() {
    return (
      <>
        <div className='grid-item p-12 t-6 d0-grid-form-top-info-left'>
          <h3>Manejos e Produtos</h3>
          <ul>
            <li>
              <strong>Dispositivo Intravaginal:</strong>{' '}
              {this.props.initialValues.implant}
            </li>
            <li>
              <strong>Estímulo Ovulatório:</strong>{' '}
              {this.props.initialValues.benzoato}
            </li>
            {!_.isEmpty(this.props.initialValues.luteolise) && (
              <li>
                <strong>Luteólise:</strong> {this.props.initialValues.luteolise}
              </li>
            )}
            {!_.isEmpty(this.props.initialValues.progesterona) && (
              <li>
                <strong>Progesterona Injetável:</strong>{' '}
                {this.props.initialValues.progesterona}
              </li>
            )}
          </ul>
        </div>

        <div className='grid-item p-12 t-6 p-display-flex d0-grid-form-top-info-right'>
          <h3>TOTAL DE MATRIZES: {this.state.count}</h3>

          <CustomSwitch
            onHandleChange={this.handleSwitchChange}
            checked={this.state.checked}
            text='Avisar sobre matrizes repetidas'
          ></CustomSwitch>
        </div>

        <div className='grid-item p-12 p-padding-vertical-1'>
          <hr />
        </div>
      </>
    )
  }

  render() {
    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={this.renderTitle()}
          buttons={this.getButtons()}
          paths={this.getPaths()}
        />
        <MainContainer>
          <div className='grid d0-grid-form'>
            {this.renderHeader()}

            <div className='grid-item p-10 d-4'>
              <TextField
                disabled={this.state.loading || this.state.iatfCows.length > 0}
                id='codVaca'
                label='ID1/Matriz'
                inputRef={this.codVaca}
                autoFocus
                onChange={this.textfieldDefaultOnChange}
                onKeyDown={this.onEnterPress}
                value={this.state.codVaca || null}
                error={!_.isEmpty(this.state.codVaca_error)}
                helperText={this.state.codVaca_error}
              />
            </div>

            <div className='grid-item p-2 d-1'>
              <Button
                disabled={this.state.iatfCows.length > 0}
                secondary
                small
                tabIndex='-1'
                style={{
                  height: '55px',
                  width: '100%',
                }}
                onClick={async () => {
                  try {
                    const resposta = await SpeechRecognition()
                    if (resposta !== null)
                      this.setState({
                        codVaca: resposta,
                        codVaca_error:
                          _.isEmpty(resposta) &&
                          REQUIRED_FIELD_MESSAGES.DEFAULT,
                      })
                  } catch (e) {
                    console.log(e)
                  }
                }}
                label={<MicIcon />}
                containerStyle={{ textAlign: 'center' }}
              />
            </div>

            <div className='grid-item p-12 d-4'>
              <TextField
                id='codLeitorBastao'
                disabled={this.state.loading || this.state.iatfCows.length > 0}
                label='ID2/Leitor de bastão'
                inputRef={this.codLeitorBastao}
                onChange={(e) => {
                  this.textfieldDefaultOnChange(e)
                  this.setState({ codVaca_error: null })
                }}
                onKeyDown={this.onEnterPress}
                value={this.state.codLeitorBastao || null}
              />
            </div>

            <div className='grid-item p-12 t-5 d-2'>
              <Autocomplete
                disabled={this.state.iatfCows.length > 0}
                id='ecc'
                options={this.props.ecc}
                value={this.state.ecc || null}
                onChange={this.autocompleteDefaultOnChangeSavingID('ecc')}
                onKeyDown={this.state.ecc && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.ecc
                )}
                useDefaultOptionSelected
                label='ECC'
                error={!_.isEmpty(this.state.ecc_error)}
                helperText={this.state.ecc_error}
              />
            </div>

            <div className='grid-item p-2 d-1'>
              <Button
                secondary
                small
                tabIndex='-1'
                style={{
                  height: '55px',
                  width: '100%',
                }}
                onClick={async () => {
                  try {
                    const answerToUser = eccSpeechRecognition(
                      await SpeechRecognition()
                    )

                    if (answerToUser !== null) {
                      this.setState({
                        ecc: answerToUser,
                      })
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }}
                label={<MicIcon />}
                containerStyle={{ textAlign: 'center' }}
              />
            </div>

            <div className='grid-item p-10 t-5 d-2'>
              <TextField
                disabled={this.state.iatfCows.length > 0}
                id='peso_EM'
                label='Peso Inicial'
                type='number'
                onChange={this.textfieldDefaultOnChange}
                onKeyDown={this.onEnterPress}
                value={this.state.peso_EM || null}
                error={!_.isEmpty(this.state.peso_EM_error)}
                helperText={this.state.peso_EM_error}
              />
            </div>

            <div className='grid-item p-2 t-2 d-1'>
              <Button
                secondary
                small
                tabIndex='-1'
                style={{
                  height: '55px',
                  width: '100%',
                }}
                onClick={async () => {
                  const resposta = await SpeechRecognition()
                  if (resposta !== null)
                    this.setState({
                      peso_EM: resposta,
                      peso_EM_error: null,
                    })
                }}
                label={<MicIcon />}
                containerStyle={{ textAlign: 'center' }}
              />
            </div>

            <div className='grid-item p-12 t-4 d-2'>
              <Autocomplete
                disabled={this.state.iatfCows.length > 0}
                id='clD0'
                label='Presença de CL'
                options={this.props.clD0}
                value={this.state.clD0 || null}
                onChange={this.autocompleteDefaultOnChangeSavingID('clD0')}
                onKeyDown={this.state.clD0 && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.clD0
                )}
                useDefaultOptionSelected
              />
            </div>

            {this.state.focusDate ? (
              <div className='grid-item p-12 p-offset-3 t-6 d-3'>
                <DateField
                  id='dataPartoIndividual'
                  label='Data do parto individual'
                  disabled={this.state.iatfCows.length > 0}
                  value={this.state.dataPartoIndividual || null}
                  onChange={(e) => this.setState({ dataPartoIndividual: e })}
                  onBlur={() => {
                    if (!this.state.dataPartoIndividual) {
                      this.setState({ focusDate: false })
                    }
                  }}
                  onKeyDown={this.onEnterPress}
                  onClickCaptureButton={() =>
                    this.setState({ dataPartoIndividual: new Date() })
                  }
                  error={
                    !this.isDateNullOrValid(this.state.dataPartoIndividual)
                  }
                />
              </div>
            ) : (
              <div className='grid-item p-12 p-offset-3 t-6 d-3'>
                <DateField
                  initialValues={[
                    this.state.dataPartosInicio,
                    this.state.dataPartosFim,
                  ]}
                  disabled={this.state.iatfCows.length > 0}
                  label='Data do parto'
                  onFocus={() => this.setState({ focusDate: true })}
                  ranged
                />
              </div>
            )}

            <div className='grid-item p-12 t-12 d-4'>
              <TextField
                disabled={this.state.iatfCows.length > 0}
                id='observacaoD0'
                label='Observação'
                onChange={this.textfieldDefaultOnChange}
                onKeyDown={this.onEnterPress}
                value={this.state.observacaoD0 || null}
                error={!_.isEmpty(this.state.observacaoD0_error)}
                helperText={this.state.observacaoD0_error}
              />
            </div>

            <div className='grid-item p-12 p-padding-vertical-1'>
              <hr />
            </div>

            <div className='grid-item p-12 p-offset-3 t-6 d-2'>
              <Autocomplete
                disabled={this.state.iatfCows.length > 0}
                id='idade_Nov_Pri'
                options={this.props.idadeMatriz}
                value={this.state.idade_Nov_Pri || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'idade_Nov_Pri'
                )}
                onKeyDown={this.state.idade_Nov_Pri && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.idadeMatriz
                )}
                useDefaultOptionSelected
                label='Idade (Meses)'
                error={!_.isEmpty(this.state.idade_Nov_Pri_error)}
                helperText={this.state.idade_Nov_Pri_error}
              />
            </div>

            <div className='grid-item p-12 t-6 d-2'>
              <Autocomplete
                disabled={this.state.iatfCows.length > 0}
                id='racaMatriz'
                options={this.props.racaMatriz}
                value={this.state.racaMatriz || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'racaMatriz'
                )}
                onKeyDown={this.state.racaMatriz && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
                  this.props.racaMatriz
                )}
                useDefaultOptionSelected
                label='Raça da Matriz'
                error={!_.isEmpty(this.state.racaMatriz_error)}
                helperText={this.state.racaMatriz_error}
              />
            </div>

            <div className='grid-item p-12 t-6 d-2'>
              <Autocomplete
                disabled={this.state.iatfCows.length > 0}
                id='ordemMatriz'
                options={this.props.ordemMatriz}
                value={this.state.ordemMatriz || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'ordemMatriz'
                )}
                onKeyDown={this.state.ordemMatriz && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
                  this.props.ordemMatriz
                )}
                useDefaultOptionSelected
                label='Ordem da Matriz'
                error={!_.isEmpty(this.state.ordemMatriz_error)}
                helperText={this.state.ordemMatriz_error}
              />
            </div>

            <div className='grid-item p-12 p-offset-3 t-6 d-2'>
              <Autocomplete
                disabled={this.state.iatfCows.length > 0}
                id='dispositivoIntravaginal'
                options={this.props.implantes}
                value={this.state.dispositivoIntravaginal || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'dispositivoIntravaginal'
                )}
                onKeyDown={
                  this.state.dispositivoIntravaginal && this.onEnterPress
                }
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.implantes
                )}
                useDefaultOptionSelected
                label='Dispositivo Intravaginal'
                error={!_.isEmpty(this.state.dispositivoIntravaginal_error)}
                helperText={this.state.dispositivoIntravaginal_error}
              />
            </div>

            <div className='grid-item p-12 t-6 d-2'>
              <Autocomplete
                disabled={this.state.iatfCows.length > 0}
                id='numero_Uso_Disp'
                options={this.props.usoImplantes}
                value={this.state.numero_Uso_Disp || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'numero_Uso_Disp'
                )}
                onKeyDown={this.state.numero_Uso_Disp && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.usoImplantes
                )}
                useDefaultOptionSelected
                label='Uso do Dispositivo Intravaginal'
                error={!_.isEmpty(this.state.numero_Uso_Disp_error)}
                helperText={this.state.numero_Uso_Disp_error}
              />
            </div>

            <div className='grid-item p-12 t-6 d-2'>
              <Autocomplete
                disabled={this.state.iatfCows.length > 0}
                id='responsavelId'
                options={this.props.usuarios}
                value={this.state.responsavelId || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'responsavelId'
                )}
                onKeyDown={this.state.responsavelId && this.onEnterPress}
                getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                  this.props.usuarios,
                  'nomeCompleto'
                )}
                useDefaultOptionSelected
                label='Responsável'
                error={!_.isEmpty(this.state.responsavelId_error)}
                helperText={this.state.responsavelId_error}
              />
            </div>

            <div className='grid-item p-12 p-display-flex p-justify-space-between'>
              <Button
                disabled={this.state.iatfCows.length > 0}
                color={COLORS.GREY}
                label='Limpar'
                onClick={this.onClean}
                startIcon={<DeleteIcon />}
              />
              <Button
                label='Salvar'
                disabled={this.state.loading || this.state.iatfCows.length > 0 || this.props.users[0].roles[0]?.name === 'Cliente (somente visualização)'}
                onClick={this.handleSave}
                startIcon={<SaveIcon />}
                containerStyle={{ textAlign: 'right' }}
              />
            </div>
          </div>

          <Prompt
            visible={this.state.searchError}
            title={'Matriz já cadastrada em outro(s) lotes'}
            message={
              this.state.searchError
                ? this.state.searchError
                : 'Erro desconhecido'
            }
            secondaryMessage={
              'Para não ver este aviso, desmarque a opção "Avisar sobre matrizes repetidas".'
            }
            buttons={[
              {
                label: 'Cancelar',
                autoFocus: false,
                onClick: () => this.setState({ searchError: false }),
              },
              {
                label: 'Salvar',
                onClick: () => {
                  this.createD0()
                },
              },
            ]}
          />

          <Prompt
            visible={
              this.state.warning && this.props.batch?.matrizBloqueada === null
            }
            title={'Atenção às recomendações ao utilizar esta funcionalidade.'}
            message={
              'Ao cadastrar uma matriz, todos os manejos abaixo devem ser preenchidos, caso saia da tela durante o cadastro, o lote será bloqueado até que a matriz incompleta seja excluida. Não há a possibilidade de editar as informações durante o cadastro após clicar em "OK" ou "Salvar", portanto, caso alguma informação esteja errada, clique em "Cancelar" para excluir a matriz que deverá ser incluida novamente a partir desta tela.'
            }
            buttons={[
              {
                label: 'Cancelar',
                autoFocus: false,
                onClick: () => {
                  if (this.props.farm.retiro) {
                    this.props.history.push(
                      `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
                    )
                  } else {
                    this.props.history.push(`/farms/${this.props.farm.id}`)
                  }
                },
              },
              {
                label: 'Concordo com os termos de uso',
                onClick: () => {
                  this.setState({ warning: false })
                },
              },
            ]}
          />

          <Prompt
            visible={this.props.batch?.matrizBloqueada !== null}
            title='Lote bloqueado!'
            message={
              this.props.batch?.matrizBloqueada &&
              `Este lote se encontra bloqueado devido ao cadastro incompleto da Matriz: ${this.state.selectedCows.find(
                (item) => item.id === this.props.batch?.matrizBloqueada
              ).codVaca
              }, para que o lote seja desbloqueado, a matriz em questão deve ser excluida na tela do lote.`
            }
            buttons={[
              {
                label: 'Ir para o Lote',
                onClick: () =>
                  this.props.history.push(
                    `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}/batches/${this.props.batch.id}`
                  ),
              },
            ]}
          />

          <Prompt
            visible={this.state.showingFinalizeConfirmation}
            title={
              this.state.count > 0 ? 'Deseja Finalizar?' : 'Erro ao finalizar'
            }
            message={
              this.state.count > 0
                ? 'Uma vez finalizado os dados não salvos serão perdidos.'
                : 'Não há matrizes cadastradas neste lote.'
            }
            buttons={
              this.state.count > 0
                ? [
                  {
                    label: 'Não',
                    onClick: () =>
                      this.setState({
                        showingFinalizeConfirmation: false,
                        confirmedBackupDownload: true,
                      }),
                  },
                  {
                    label: 'Sim',
                    onClick: this.state.confirmedBackupDownload
                      ? () => this.finalize(true)
                      : () =>
                        this.setState({
                          showingFinalizeConfirmation: false,
                          showingNoBackupConfirmation: true,
                        }),
                  },
                ]
                : [
                  {
                    label: 'Bazinga',
                    onClick: () => {
                      this.setState({ showingFinalizeConfirmation: false })
                    },
                  },
                ]
            }
          />
          <Prompt
            visible={this.state.searchError}
            title={'Matriz já cadastrada em outro(s) lotes'}
            message={
              this.state.searchError
                ? this.state.searchError
                : 'Erro desconhecido'
            }
            secondaryMessage={
              'Para não ver este aviso, desmarque a opção "Avisar sobre matrizes repetidas".'
            }
            buttons={[
              {
                label: 'Cancelar',
                autoFocus: false,
                onClick: () => this.setState({ searchError: false }),
              },
              {
                label: 'Salvar',
                onClick: () => {
                  this.createD0()
                },
              },
            ]}
          />

          <Prompt
            visible={this.state.showingNoBackupConfirmation}
            title='Atenção!'
            message='O comprovante de manejo pode ser necessário durante um eventual cenário de perda de dados. Não baixar o comprovante poderá dificultar a recuperação das informações desse manejo. Deseja continuar mesmo assim?'
            buttons={[
              {
                label: 'Cancelar',
                onClick: () =>
                  this.setState({
                    showingNoBackupConfirmation: false,
                    showingFinalizeConfirmation: true,
                  }),
              },
              {
                label: 'Sim',
                onClick: () => this.finalize(false),
              },
            ]}
          />

          {this.state.iatfCows.length > 0 && (
            <>
              <Divider
                style={{
                  marginTop: '2em',
                  width: '100%',
                  height: '2px',
                  marginBottom: '32px',
                }}
              />

              <div className='d-12 p-12'>
                <PaginatedTable
                  style={{ marginTop: '30px' }}
                  columns={this.getIatfTableColumns()}
                  data={this.state.iatfCows}
                  skipPagination
                  className='table-iatf'
                />
              </div>
              <div
                className='grid-item p-12 p-display-flex p-justify-space-between'
                style={{ marginTop: '30px' }}
              >
                <Button
                  color={COLORS.GREY}
                  label='Cancelar'
                  onClick={() => {
                    this.deleteCow()
                    this.setState({ renderIndex: 0, cowsWithBlockingObs: [] })
                  }}
                  startIcon={<DeleteIcon />}
                />
                <Button
                  label='Finalizar'
                  disabled={
                    this.state.iatfCows.length ===
                      this.props.allBatches.length ||
                      this.state.renderIndex === this.props.allBatches.length
                      ? false
                      : true
                  }
                  onClick={() =>
                    this.setState({ showingFinalizeConfirmation: true })
                  }
                  startIcon={<SaveIcon />}
                  containerStyle={{ textAlign: 'right' }}
                />
              </div>
            </>
          )}

          <Divider
            style={{
              width: '100%',
              marginTop: '2em',
              height: '2px',
              marginBottom: '-16px',
            }}
          />
          <MainContainer
            containerTitle={`Matrizes`}
            hasSearchWithoutFocus
            searchLabel='Buscar'
            handleSearch={this.handleSearch}
            skipVersion={true}
          >
            <ResponsiveTable
              columns={this.getTableColumnData()}
              data={this.state.selectedCows}
              noDataMessage='Ainda não existe nenhuma vaca neste lote!'
              toDataExport={this.props}
              className='table-d0'
            />
          </MainContainer>
        </MainContainer>
      </Container>
    )
  }
}

async function parseD0Info(protocol, d0, tipoManejo) {
  let ret = {
    implant: '',
    benzoato: '',
    luteolise: '',
    progesterona: '',
  }
  if (protocol && protocol.managementProtocols) {
    const d0s = _.filter(
      protocol.managementProtocols,
      (managementProtocol) => managementProtocol.day === d0.id
    )
    const benzoato = _.find(
      tipoManejo,
      (manejo) => manejo.valor === 'Estímulo Ovulatório'
    )
    const luteolise = _.find(
      tipoManejo,
      (manejo) => manejo.valor === 'Luteólise'
    )
    const progesterona = _.find(
      tipoManejo,
      (manejo) => manejo.valor === 'Progesterona Injetável'
    )

    _.each(d0s, async (d0) => {
      if (d0.implantId) {
        ret.implant = (
          await utils.getElement(AVAILABLE_ENTITIES.DOMAIN_VALUES, d0.implantId)
        ).valor
      }
      const hormone = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        d0.hormoneTypeId
      )
      const dosage = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        d0.hormoneDosageId
      )

      const dosageValue = dosage ? dosage.valor : d0.hormoneDosageValue

      switch (d0.managementId) {
        case benzoato.id:
          ret.benzoato = `${hormone.valor}${_.isEmpty(dosageValue) ? '' : ` - ${dosageValue} mL`
            }`
          break
        case luteolise.id:
          ret.luteolise = `${hormone.valor}${_.isEmpty(dosageValue) ? '' : ` - ${dosageValue} mL`
            }`
          break
        case progesterona.id:
          ret.progesterona = `${hormone.valor}${_.isEmpty(dosageValue) ? '' : ` - ${dosageValue} mL`
            }`
          break
        default:
      }
    })
  }
  return ret
}

const propsFromDatabase = async (props) => {
  const batchIds = props.match.params.id.split(';')

  const batch = await utils.getElement(
    AVAILABLE_ENTITIES.BATCHES,
    props.match.params.id
  )

  const corral = await utils.getElement(
    AVAILABLE_ENTITIES.CORRALS,
    batch.retiroId
  )

  const descendentBatches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'originalBatch_id',
    batch.id
  )

  const lotesIds = []
  descendentBatches.map((db) => lotesIds.push(db.id))
  lotesIds.push(batchIds[0])

  const allBatches = await batches.getBatchesByBatchIds(lotesIds)

  const condicaoDG = _.sortBy(await utils.getDomainValuesBy('Condição DG'), [
    (condicao) => condicao.valor !== 'Prenhe',
    (condicao) => condicao.valor !== 'Prenhe Touro',
    (condicao) => condicao.valor !== 'Vazia',
    (condicao) => condicao.valor !== 'Descarte Prenhe',
    (condicao) => condicao.valor !== 'Descarte Vazia',
  ])

  const temperamento = _.sortBy(await utils.getDomainValuesBy('Temperamento'), [
    (temperamento) => temperamento.valor.trim().toLowerCase() !== 'andando',
    (temperamento) => temperamento.valor.trim().toLowerCase() !== 'marchando',
    (temperamento) => temperamento.valor.trim().toLowerCase() !== 'correndo',
  ])

  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    corral.fazendaId
  )

  const users = await utils.getWithRelation(
    AVAILABLE_ENTITIES.USERS,
    'farms__rel__users',
    'farm_id',
    farm.id
  )

  const partidas = await utils.getWithParam(
    AVAILABLE_ENTITIES.PARTIDA,
    'farm_id',
    farm.id
  )

  const bulls = await utils.getWithParam(
    AVAILABLE_ENTITIES.BULLS_NEW,
    'id',
    Q.oneOf(partidas.map((f) => f.touroId))
  )

  const usuarios = await utils.getWithRelation(
    AVAILABLE_ENTITIES.USERS,
    'farms__rel__users',
    'farm_id',
    corral.fazendaId
  )

  const dias = await utils.getDomainValuesBy('Dias')
  const protocol =
    batch.protocoloId &&
    (await utils.getElement(AVAILABLE_ENTITIES.PROTOCOLS, batch.protocoloId))
  const d0 = _.find(dias, { valor: 'D0' })
  const tipoManejo = await utils.getDomainValuesBy('Tipo de Manejo')
  const d0Info = await parseD0Info(protocol, d0, tipoManejo)

  return {
    requiredFields: [
      'codVaca',
      'responsavelId',
      'dispositivoIntravaginal',
      'numero_Uso_Disp',
      'racaMatriz',
      'ordemMatriz',
    ],
    allBatches,
    descendentBatches,
    batch,
    corral,
    condicaoDG,
    farm,
    usuarios: usuarios ? usuarios : [],
    protocol,
    users,
    bulls,
    partidas,
    vacas: await utils.getWithParam(
      AVAILABLE_ENTITIES.D0S,
      'batch_id',
      props.match.params.id
    ),
    ecc: _.sortBy(await utils.getDomainValuesBy('ECC'), [
      (ecc) => parseFloat(ecc.valor),
    ]),
    ordemMatriz: await utils.getDomainValuesBy('Ordem da Matriz Predominante'),
    racaMatriz: await utils.getDomainValuesBy('Raça da Matriz predominante'),
    racaBezerro: await utils.getDomainValuesBy('Raça do bezerro'),
    sexoBezerro: await utils.getDomainValuesBy('Sexo do Bezerro'),
    idadeMatriz: _.sortBy(await utils.getDomainValuesBy('Idade da Matriz'), [
      (idade) => parseInt(idade.valor.split(' ')[0]),
    ]),
    tipoPrenhe: await utils.getDomainValuesBy('Tipo Prenhe'),
    clVazia: await utils.getDomainValuesBy('CL'),
    implantes: _.sortBy(await utils.getDomainValuesBy('Dispositivo'), [
      (implant) => !implant.valor.toLowerCase().startsWith('zoetis'),
      (implant) => implant.valor.toLowerCase(),
    ]),
    usoImplantes: _.sortBy(
      await utils.getDomainValuesBy('Tipo de Implante Predominante'),
      ['valor']
    ),
    clD0: _.sortBy(await utils.getDomainValuesBy('CL'), [
      (cl) => !cl.valor.toLowerCase().startsWith('sim'),
      (cl) => !cl.valor.toLowerCase().startsWith('não'),
      (cl) => cl.valor.toLowerCase(),
    ]),
    initialValues: {
      ...d0Info,
      ordemMatriz: batch.ordemMatriz,
      racaMatriz: batch.racaMatriz,
      idade_Nov_Pri: batch.idadeMatriz,
      dispositivoIntravaginal: batch.dispositivo,
      dataPartosInicio: batch.dataPartosInicio,
      dataPartosFim: batch.dataPartosFim,
      numero_Uso_Disp: batch.numeroUsoDispositivo,
      responsavelId: batch.usuarioId,
    },
    cio: await utils.getDomainValuesBy('Cio'),
    gnhr: await utils.getDomainValuesBy('GnRH'),
    temperamento,
    d0,
    user: await utils.getElement(
      AVAILABLE_ENTITIES.USERS,
      (
        await getLoggedUser()
      ).userId
    ),
  }
}

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(D0AfterResync)
)
